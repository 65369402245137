import { booleanAttribute, ChangeDetectionStrategy, Component, ElementRef, inject, Input, input, output } from '@angular/core';
import { HTMLStencilElement } from '@caronsale/enzo/dist/types/stencil-public-runtime';
import { Components, EnzoCheckboxInputPayload, ErrorStateMatchStrategy } from '@caronsale/enzo';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'enzo-checkbox',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  standalone: true,
  host: {
    '[label]': 'label()',
    '[description]': 'description()',
    '[labelPosition]': 'labelPosition()',
    '[validation]': 'validation()',
    '[indeterminate]': 'indeterminate()',
  },
})
export class EnzoCheckboxComponent {
  private el: Components.EnzoCheckbox & HTMLStencilElement = inject(ElementRef).nativeElement;

  public label = input<string>('');
  public description = input<string>('');
  public labelPosition = input<'leading' | 'trailing'>('trailing');
  public validation = input<{
    type?: 'angular';
    errorStateMatch: ErrorStateMatchStrategy;
  }>({ errorStateMatch: 'onTouch' });
  public indeterminate = input(false, { transform: booleanAttribute });

  @Input()
  public set checked(checkedValue: boolean) {
    this.el.checked = booleanAttribute(checkedValue);
  }

  public get checked(): boolean {
    return this.el.checked;
  }

  @Input()
  public get disabled(): boolean {
    return this.el.disabled;
  }

  public set disabled(newValue: boolean) {
    this.el.disabled = newValue;
  }

  public enzoInput = output<CustomEvent<EnzoCheckboxInputPayload>>();

  public constructor() {
    fromEvent(this.el, 'enzoInput')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        event.stopImmediatePropagation();
        this.enzoInput.emit(event as CustomEvent<EnzoCheckboxInputPayload>);
      });
  }

  public forceFocus() {
    this.el.focus({ preventScroll: true });

    requestAnimationFrame(() => {
      this.el.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    });
  }

  public async toggleCheck(options: { forceToggle?: boolean; preventAnimation?: boolean } = {}) {
    return this.el.toggleCheck(options);
  }

  public async check(options: { forceCheck?: boolean; preventAnimation?: boolean } = {}) {
    return this.el.check(options);
  }

  public uncheck(options: { forceUncheck?: boolean; preventAnimation?: boolean } = {}) {
    this.el.uncheck(options);
  }
}
