import { ChangeDetectionStrategy, Component, ElementRef, inject, Input, input, output } from '@angular/core';
import { HTMLStencilElement } from '@caronsale/enzo/dist/types/stencil-public-runtime';
import { Components, EnzoRadioButtonGroupInputPayload, Spacer } from '@caronsale/enzo';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'enzo-radio-button-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  standalone: true,
  host: {
    '[layout]': 'layout()',
    '[spacing]': 'spacing()',
  },
})
export class EnzoRadioButtonGroupComponent {
  private el: Components.EnzoRadioButtonGroup & HTMLStencilElement = inject(ElementRef).nativeElement;

  public layout = input<'none' | 'horizontal' | 'vertical'>('vertical');
  public spacing = input<Spacer>('4');

  @Input()
  public get value(): boolean {
    return this.el.value;
  }

  public set value(valueValue: boolean) {
    this.el.value = valueValue;
  }

  public enzoInput = output<CustomEvent<EnzoRadioButtonGroupInputPayload>>();

  public constructor() {
    fromEvent(this.el, 'enzoInput')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        event.stopImmediatePropagation();
        this.enzoInput.emit(event as CustomEvent<EnzoRadioButtonGroupInputPayload>);
      });
  }

  public async selectRadioButton(
    value: string,
    options: {
      forceSelection?: boolean;
      preventAnimation?: boolean;
    } = {},
  ) {
    return this.el.selectRadioButton(value, options);
  }
}
