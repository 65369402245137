<ng-template #loaderSkeleton>
  <app-cockpit-carousel-skeleton />
</ng-template>

<div class="container" *ngIf="activityCards$ | async as activityCards">
  <div class="header" [class.has-multiple-cards]="activityCards.length > 1">
    <app-cockpit-activity-cards [activityCards]="activityCards" (refresh)="refreshActivityCards()" testid="activity-cards"></app-cockpit-activity-cards>
    @if (searchRevampFeatureFlag() === true) {
      <app-cockpit-quick-search-revamp testid="quick-search-revamp" />
    } @else if (searchRevampFeatureFlag() === false) {
      <app-cockpit-quick-search testid="quick-search" />
    }
  </div>
  <div class="sections">
    <!-- Auction Rrooms -->
    <app-cockpit-section
      [loaderTemplate]="loaderSkeleton"
      [source]="auctionRooms$"
      titleIcon="car-category"
      [titleLabel]="'buyer-cockpit.auction-room' | translate"
      [showMoreVehiclesCard]="false"
      testid="auction-rooms-section">
      <ng-template let-items #content>
        <app-auction-room-card
          *ngFor="let auctionRoom of items.items; trackBy: trackByAuctionRoomUuid"
          [auctionRoom]="auctionRoom"
          testid="auction-room-card"></app-auction-room-card>
      </ng-template>
    </app-cockpit-section>

    <!-- Private auctions -->
    <app-cockpit-section
      [loaderTemplate]="loaderSkeleton"
      [source]="privateAuctions$"
      titleIcon="private-owner"
      [titleLabel]="'buyer-cockpit.private-auctions' | translate"
      [isNewFeature]="true"
      [buttonLabel]="'buyer-cockpit.go-to-private-auctions' | translate"
      buttonIcon="arrow-right"
      (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RUNNING_AUCTIONS, PRIVATE_AUCTIONS_FILTER, COCKPIT_LIST_TYPE.PrivateVehicle)"
      testid="private-auctions-section">
      <ng-template let-items #content>
        <app-cockpit-auction-card
          *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
          [auctionUuid]="auctionUuid"
          [auctionDetailsUrlTemplate]="runningPrivateAuctionDetailsUrlTemplate"
          (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.PrivateVehicle, auctionUuid)"></app-cockpit-auction-card>
      </ng-template>
    </app-cockpit-section>

    <!-- Mercedes-Benz buyers only -->
    @if (selectedLookAndFeel$ | async; as selectedLookAndFeel) {
      @if (hasMBAdvertisementType(selectedLookAndFeel.visibleAdvertisementTypes) && selectedLookAndFeel.partnerName === EPartnerName.MERCEDES_BENZ) {
        <app-cockpit-section
          class="mb-section"
          [style.--backgroundGradient]="selectedLookAndFeel.bannerGradient"
          [partnerLogo]="selectedLookAndFeel.partnerLogo"
          [loaderTemplate]="loaderSkeleton"
          [source]="mercedesBenzAuctions$"
          [titleLabel]="'buyer-cockpit.mercedes-benz-section.title' | translate"
          titleColor="white"
          [noItemsText]="'buyer-cockpit.mercedes-benz-section.no-auctions-found' | translate"
          buttonIcon="arrow-right"
          [isNewFeature]="false"
          buttonColor="white"
          [minItems]="0"
          [buttonLabel]="'buyer-cockpit.go-to-mercedes-benz-auctions' | translate"
          (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RUNNING_AUCTIONS, MERCEDES_BENZ_AUCTIONS_FILTER)"
          testid="mb-auctions-section">
          <ng-template let-items #content>
            <app-cockpit-auction-card
              *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
              [auctionUuid]="auctionUuid"
              [auctionDetailsUrlTemplate]="runningMercedesBenzAuctionDetailsUrlTemplate"></app-cockpit-auction-card>
          </ng-template>
        </app-cockpit-section>
      }
    }

    <!-- Recommended vehicles -->
    <app-cockpit-section
      [loaderTemplate]="loaderSkeleton"
      [source]="recommendedAuctions$"
      titleIcon="recommended"
      [titleLabel]="'buyer-cockpit.recommended-auctions' | translate"
      [buttonLabel]="'buyer-cockpit.go-to-latest-auctions' | translate"
      buttonIcon="arrow-right"
      [showCount]="false"
      (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RECOMMENDED_AUCTIONS)"
      testid="recommended-auctions-section">
      <ng-template let-items #content>
        <app-cockpit-auction-card
          *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
          [auctionUuid]="auctionUuid"
          [recommendationId]="items.recommendationId"
          [auctionDetailsUrlTemplate]="recommendedAuctionDetailsUrlTemplate"
          testid="recommended-auction"
          (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.Recommended, auctionUuid)"></app-cockpit-auction-card>
      </ng-template>
    </app-cockpit-section>

    <!-- Watchlist vehicles -->
    <app-cockpit-section
      [loaderTemplate]="loaderSkeleton"
      [source]="watchlistAuctions$"
      titleIcon="favorite"
      [titleLabel]="'buyer-cockpit.watchlist-auctions' | translate"
      [buttonLabel]="'buyer-cockpit.go-to-latest-auctions' | translate"
      buttonIcon="arrow-right"
      (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.WATCHLIST)"
      testid="watchlist-auctions-section">
      <ng-template let-items #content>
        <app-cockpit-auction-card
          *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
          cardSize="small"
          [auctionUuid]="auctionUuid"
          [auctionDetailsUrlTemplate]="watchlistAuctionDetailsUrlTemplate"
          testid="watchlist-auction"
          (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.Watchlist, auctionUuid)"></app-cockpit-auction-card>
      </ng-template>
    </app-cockpit-section>

    <!-- Discount auctions -->
    @if (selectedLookAndFeel$ | async; as selectedLookAndFeel) {
      @if (selectedLookAndFeel.partnerName !== EPartnerName.MERCEDES_BENZ && strategicPartnerFeatureFlag() && searchRevampFeatureFlag()) {
        <app-cockpit-section
          class="discount-section"
          [loaderTemplate]="loaderSkeleton"
          [source]="discountAuctions$"
          titleIcon="voucher"
          [titleLabel]="'buyer-cockpit.discount-vehicles' | translate"
          [subTitleLabel]="'buyer-cockpit.discount-vehicles-subtitle' | translate"
          titleColor="white"
          subTitleColor="white"
          titleIconColor="white"
          [buttonLabel]="'buyer-cockpit.go-to-latest-auctions' | translate"
          buttonIcon="arrow-right"
          buttonColor="white"
          [showCount]="true"
          (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RUNNING_AUCTIONS, DISCOUNT_AUCTIONS_FILTER)"
          testid="discount-auctions-section">
          <ng-template let-items #content>
            <app-cockpit-auction-card
              *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
              [auctionUuid]="auctionUuid"
              [auctionDetailsUrlTemplate]="runningDiscountAuctionDetailsUrlTemplate"
              testid="discount-auction"
              (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.Discount, auctionUuid)"></app-cockpit-auction-card>
          </ng-template>
        </app-cockpit-section>
      }
    }

    <!-- Last searched vehicles-->
    @if (showLastSearchedSectionFeatureFlag()) {
      <app-cockpit-section
        [loaderTemplate]="loaderSkeleton"
        [source]="lastSearchedAuctions$"
        titleIcon="search"
        [isNewFeature]="true"
        [titleLabel]="'buyer-cockpit.last-search' | translate"
        [buttonLabel]="'buyer-cockpit.go-to-latest-auctions' | translate"
        [subTitleLabel]="lastSearchFilterSubtitle()"
        subTitleColor="black-light"
        buttonIcon="arrow-right"
        (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RUNNING_AUCTIONS, LAST_SEARCH_AUCTIONS_FILTER())"
        testid="last-searched-auctions-section">
        <ng-template let-items #content>
          <app-cockpit-auction-card
            *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
            cardSize="medium"
            [auctionUuid]="auctionUuid"
            [auctionDetailsUrlTemplate]="lastSearchAuctionDetailsUrlTemplate()"
            (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.LastSearched, auctionUuid)" />
        </ng-template>
      </app-cockpit-section>
    }

    <!-- High value vehicles -->
    @if (!showLastSearchedSectionFeatureFlag()) {
      <app-cockpit-section
        [loaderTemplate]="loaderSkeleton"
        [source]="highValueVehiclesAuctions$"
        titleIcon="vehicle-high-price"
        [titleLabel]="'buyer-cockpit.high-value-vehicles-auctions' | translate"
        [isNewFeature]="true"
        [minItems]="5"
        [buttonLabel]="'buyer-cockpit.go-to-high-value-vehicles-auctions' | translate"
        buttonIcon="arrow-right"
        (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RUNNING_AUCTIONS, HIGH_VALUE_VEHICLES_AUCTIONS_FILTER, COCKPIT_LIST_TYPE.HighValueVehicle)"
        testid="private-auctions-section">
        <ng-template let-items #content>
          <app-cockpit-auction-card
            *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
            [auctionUuid]="auctionUuid"
            [auctionDetailsUrlTemplate]="runningHighValueVehiclesAuctionDetailsUrlTemplate"
            (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.HighValueVehicle, auctionUuid)"></app-cockpit-auction-card>
        </ng-template>
      </app-cockpit-section>
    }

    <!-- Last viewed auctions -->
    @if (showLastViewedSectionFeatureFlag()) {
      <app-cockpit-section
        [loaderTemplate]="loaderSkeleton"
        [source]="lastViewedAuctions$"
        titleIcon="visibility"
        [titleLabel]="'buyer-cockpit.last-viewed-auction' | translate"
        [showCount]="false"
        [showMoreVehiclesCard]="false"
        testid="last-viewed-auctions-section">
        <ng-template let-items #content>
          <app-cockpit-auction-card
            cardSize="small"
            *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
            [auctionUuid]="auctionUuid"
            [auctionDetailsUrlTemplate]="lastViewedAuctionDetailsUrlTemplate"
            (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.LastViewed, auctionUuid)"></app-cockpit-auction-card>
        </ng-template>
      </app-cockpit-section>
    }

    <!-- Purchase preference vehicles-->
    @if (showPurchasePreferenceFeatureFlag()) {
      <app-cockpit-section
        [loaderTemplate]="loaderSkeleton"
        [source]="purchasePreferenceAuctions$"
        titleIcon="adjust"
        [isNewFeature]="true"
        [titleLabel]="'buyer-cockpit.based-on-preferences' | translate"
        [buttonLabel]="'buyer-cockpit.go-to-latest-auctions' | translate"
        [subTitleLabel]="purchasePreferenceFilterSubtitle()"
        subTitleColor="black-light"
        buttonIcon="arrow-right"
        (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RUNNING_AUCTIONS, PURCHASE_PREFERENCE_AUCTIONS_FILTER())"
        testid="purchase-preference-auctions-section">
        <ng-template let-items #content>
          <app-cockpit-auction-card
            *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
            cardSize="medium"
            [auctionUuid]="auctionUuid"
            [auctionDetailsUrlTemplate]="purchasePreferenceAuctionDetailsUrlTemplate()"
            (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.PurchasePreference, auctionUuid)" />
        </ng-template>
      </app-cockpit-section>
    }

    <!-- Electric vehicles -->
    @if (!showLastSearchedSectionFeatureFlag()) {
      <app-cockpit-section
        [loaderTemplate]="loaderSkeleton"
        [source]="electricVehiclesAuctions$"
        titleIcon="vehicle-electric"
        [titleLabel]="'buyer-cockpit.electric-vehicle-auctions' | translate"
        [isNewFeature]="true"
        [minItems]="5"
        [buttonLabel]="'buyer-cockpit.go-to-electric-vehicle-auctions' | translate"
        buttonIcon="arrow-right"
        (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RUNNING_AUCTIONS, ELECTRIC_VEHICLES_AUCTIONS_FILTER, COCKPIT_LIST_TYPE.ElectricVehicle)"
        testid="private-auctions-section">
        <ng-template let-items #content>
          <app-cockpit-auction-card
            *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
            [auctionUuid]="auctionUuid"
            [auctionDetailsUrlTemplate]="runningElectricVehiclesAuctionDetailsUrlTemplate"
            (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.ElectricVehicle, auctionUuid)"></app-cockpit-auction-card>
        </ng-template>
      </app-cockpit-section>
    }

    <!-- Recent auctions -->
    <app-cockpit-section
      [loaderTemplate]="loaderSkeleton"
      [source]="runningAuctions$"
      titleIcon="car-category"
      [titleLabel]="'buyer-cockpit.latest-auctions' | translate"
      [buttonLabel]="'buyer-cockpit.go-to-latest-auctions' | translate"
      buttonIcon="arrow-right"
      (showMoreClick)="handleShowMoreClick(EBuyerAuctionTabId.RUNNING_AUCTIONS)"
      testid="running-auctions-section">
      <ng-template let-items #content>
        <app-cockpit-auction-card
          *ngFor="let auctionUuid of items.auctionUuids; trackBy: trackByUuid"
          cardSize="medium"
          [auctionUuid]="auctionUuid"
          [auctionDetailsUrlTemplate]="runningAuctionDetailsUrlTemplate"
          testid="running-auction"
          (navigateToAuctionDetails)="trackCardClick(COCKPIT_LIST_TYPE.Running, auctionUuid)"></app-cockpit-auction-card>
      </ng-template>
    </app-cockpit-section>
  </div>
</div>
