import { enumToArray } from '@cosCoreUtils/type-script-utils';
import { EEuroNorm, EFuelType, ETransmissionType, EVehicleCategory, EVehicleColor, EVehicleDoors } from '@caronsale/cos-vehicle-models';
import { CarOnSaleUtils } from '@cosCoreUtils/CarOnSaleUtils';

export const MIN_PS = 10;
export const MAX_PS = 450;
export const STEP_PS = 10;

export abstract class AuctionRanges {
  public static readonly doors = enumToArray(EVehicleDoors, EVehicleDoors.UNKNOWN);
  public static readonly countries = [...CarOnSaleUtils.AVAILABLE_COUNTRIES];
  public static readonly registrationYears = CarOnSaleUtils.EZS.map(String);
  public static readonly prices = CarOnSaleUtils.PRICES;
  public static readonly mileages = CarOnSaleUtils.MILEAGES;
  public static readonly transmissionTypes = enumToArray(ETransmissionType, ETransmissionType.UNKNOWN);
  public static readonly enginePower = CarOnSaleUtils.generateNumberSequence(MIN_PS, MAX_PS, [[MAX_PS, STEP_PS]]);
  public static readonly fuelTypes = enumToArray(EFuelType, EFuelType.UNKNOWN);
  public static readonly colors = enumToArray(EVehicleColor, EVehicleColor.UNKNOWN);
  public static readonly euroNorms = enumToArray(EEuroNorm, EEuroNorm.OTHER);
  public static readonly preOwners = Array.from(Array(9), (_, i) => {
    return i + 1;
  });
  public static readonly vehicleCategory = enumToArray(EVehicleCategory, EVehicleCategory.UNKNOWN);
  public static readonly numberOfSeats = Array.from(Array(7), (_, i) => i + 2);
  public static readonly searchRadius = [50, 100, 150, 200, 300, 400, 500];
}
