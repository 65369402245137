import { booleanAttribute, ChangeDetectionStrategy, Component, ElementRef, inject, Input, input } from '@angular/core';
import { HTMLStencilElement } from '@caronsale/enzo/dist/types/stencil-public-runtime';
import { Components } from '@caronsale/enzo';

@Component({
  selector: 'enzo-radio-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  standalone: true,
  host: {
    '[value]': 'value()',
    '[label]': 'label()',
    '[description]': 'description()',
    '[labelPosition]': 'labelPosition()',
    '[disabled]': 'disabled()',
  },
})
export class EnzoRadioButtonComponent {
  private el: Components.EnzoRadioButton & HTMLStencilElement = inject(ElementRef).nativeElement;

  public value = input<any>(null);
  public label = input<string>('');
  public description = input<string>('');
  public labelPosition = input<'leading' | 'trailing'>('trailing');
  public disabled = input(false, { transform: booleanAttribute });

  @Input()
  public get selected(): boolean {
    return this.el.selected;
  }

  public set selected(selected: boolean) {
    this.el.selected = booleanAttribute(selected);
  }

  public forceFocus() {
    this.el.focus({ preventScroll: true });

    requestAnimationFrame(() => {
      this.el.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    });
  }
}
