import { AfterViewInit, DestroyRef, Directive, ElementRef, inject, Injector, Input, OnChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { combineLatest, of, startWith } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Directive({})
export class ValueAccessor implements ControlValueAccessor, AfterViewInit, OnChanges {
  private onChange: (value: any) => void = () => {
    /**/
  };
  protected onTouched: () => void = () => {
    /**/
  };
  protected lastValue: any;
  private isUsed = false;

  @Input()
  public errorStateMatch: 'onTouch' | 'onDirty' | 'immediately' | 'never' = 'onTouch';

  protected el: ElementRef = inject(ElementRef);
  private injector: Injector = inject(Injector);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private translateService: TranslateService = inject(TranslateService, { optional: true });

  public ngOnChanges() {
    if (this.isUsed) {
      this.el.nativeElement.validation = {
        type: 'angular',
        errorStateMatch: this.errorStateMatch,
      };
    }
  }

  public ngAfterViewInit() {
    this.ngOnChanges();
    if (this.isUsed) {
      const ngControl = this.injector.get(NgControl, null, { optional: true });
      if (ngControl?.statusChanges) {
        combineLatest({
          status: ngControl.statusChanges.pipe(startWith(ngControl.status)),
          langChangeEvent: this.translateService?.onLangChange?.pipe(startWith(null)) || of(null),
        })
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(({ status }) => {
            const validationResult: any = { isValid: status !== 'INVALID' };
            if (ngControl!.errors) {
              let message: string;
              let type: string;
              Object.values(ngControl!.errors).forEach((error: any) => {
                if (error && typeof error === 'object') {
                  if (typeof error.messageKey === 'string') {
                    const translatedMessage = this.translateService.instant(error.messageKey, error.translationParams);
                    message = message ? message + '\n' + translatedMessage : translatedMessage;
                  }
                  if (typeof error.type !== 'string') {
                    // undefined means "invalid"
                    type = 'invalid';
                  } else if (type !== 'invalid') {
                    // if it is already "invalid", it stays "invalid"
                    type = error.type;
                  }
                }
              });
              validationResult.message = message;
              validationResult.type = type;
            }
            const nativeEl = this.el.nativeElement;
            nativeEl.validationResult = validationResult;
            nativeEl.isTouched = ngControl!.touched;
            nativeEl.isDirty = ngControl!.dirty;
          });
      }
    }
  }

  public writeValue(value: any) {
    this.el.nativeElement.value = this.lastValue = value;
  }

  public handleChangeEvent(value: any) {
    if (value !== this.lastValue) {
      this.lastValue = value;
      this.onChange(value);
    }
  }

  public registerOnChange(fn: (value: any) => void) {
    this.isUsed = true;
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.isUsed = true;
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this.el.nativeElement.disabled = isDisabled;
  }
}
