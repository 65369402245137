import { Directive } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessor } from './value-accessor';
import { parseGermanLocaleNumberString } from './utils';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'enzo-textfield[type=number]',
  host: {
    '(enzoInput)': 'handleChangeEvent($event.target.value)',
    '(focusout)': 'onTouched()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NumericValueAccessorDirective,
      multi: true,
    },
  ],
})
export class NumericValueAccessorDirective extends ValueAccessor {
  public handleChangeEvent(value: string) {
    super.handleChangeEvent(value ? parseGermanLocaleNumberString(value) : null);
  }

  public writeValue(value: any) {
    super.writeValue(value == null ? '' : value);
  }
}
