import { booleanAttribute, ChangeDetectionStrategy, Component, ElementRef, inject, input, output } from '@angular/core';
import { Components, EnzoClickPayload, EnzoIcon } from '@caronsale/enzo';
import { HTMLStencilElement } from '@caronsale/enzo/dist/types/stencil-public-runtime';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'enzo-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  standalone: true,
  host: {
    '[label]': 'label()',
    '[disabled]': 'disabled()',
    '[href]': 'href()',
    '[type]': 'type()',
    '[kind]': 'kind()',
    '[variant]': 'variant()',
    '[size]': 'size()',
    '[stretch]': 'stretch()',
    '[icon]': 'icon()',
    '[iconPosition]': 'iconPosition()',
    '[iconOnly]': 'iconOnly()',
    '[loading]': 'loading()',
  },
})
export class EnzoButtonComponent {
  private el: Components.EnzoButton & HTMLStencilElement = inject(ElementRef).nativeElement;

  public label = input<string>('');
  public disabled = input(false, { transform: booleanAttribute });
  public href = input<string>('');
  public type = input<'button' | 'submit' | 'link'>('button');
  public kind = input<'primary' | 'secondary' | 'toggle'>('primary');
  public variant = input<'normal' | 'danger' | 'highlight'>('normal');
  public size = input<'small' | 'normal'>('normal');
  public stretch = input(false, { transform: booleanAttribute });
  public icon = input<EnzoIcon['icon']>();
  public iconPosition = input<'leading' | 'trailing'>('trailing');
  public iconOnly = input(false, { transform: booleanAttribute });
  public loading = input(false, { transform: booleanAttribute });

  public enzoClick = output<CustomEvent<EnzoClickPayload>>();

  public constructor() {
    fromEvent(this.el, 'enzoClick')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        event.stopImmediatePropagation();
        this.enzoClick.emit(new CustomEvent('enzoClick', { detail: {} as EnzoClickPayload }));
      });
  }

  public press(options: { forceClick?: boolean } = {}) {
    if (!(this.disabled() || this.loading()) || options.forceClick) {
      this.enzoClick.emit(new CustomEvent('enzoClick', { detail: {} as EnzoClickPayload }));
    }
  }
}
