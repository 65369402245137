import { inject, Inject, Injectable, NgZone } from '@angular/core';
import { catchError, filter, Observable, of, take, tap, timeout } from 'rxjs';
import { HeartbeatService, takeEveryNth } from '@caronsale/frontend-services';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Amplitude, AmplitudeTrackingEventKeys, AmplitudeTrackingEventPayload, FeatureFlag, FeatureFlagEnabled } from './amplitude/amplitude';
import { IGeneralUser } from '@caronsale/cos-models';
import { IdentifyProperties } from './amplitude/ampli';
import { AppEnvironment, IAppEnvironment } from '@cosCoreEnvironments/IAppEnvironment';
import { LookAndFeelService } from '@cosBuyer/services/look-and-feel/look-and-feel.service';

/**
 * Refresh interval for the feature flags in seconds
 */
const FEATURE_FLAGS_REFRESH_INTERVAL_IN_SECONDS = 60;

/**
 * Feature flags defined on amplitude that we can access in the code.
 * They consist on a value and a payload. By default the value is "on" and the payload is undefined.
 * They are environment based
 */
interface FeatureFlags {
  /**
   * DO NOT USE: Used for testing purposes
   */
  'test-flag': FeatureFlag<FeatureFlagEnabled, { testPayload: string }>;
  /**
   * MPW flags
   */
  'mpw-price-moderation': FeatureFlag;
  'mpw-search-revamp': FeatureFlag;
  'mpw-hot-bid-bidding-info-refresh-interval': FeatureFlag<FeatureFlagEnabled, number>;
  'mpw-non-hot-bid-bidding-info-refresh-interval': FeatureFlag<FeatureFlagEnabled, number>;
  'mpw-use-push-updates-for-bidding-data-info': FeatureFlag;
  'mpw-technical-push-logging': FeatureFlag;
  'mpw-technical-push-report-delivery': FeatureFlag;
  'mpw-enable-mpr-similar-vehicles-button': FeatureFlag;
  'mpw-power-list': FeatureFlag;
  'mpw-power-list-items-per-page': FeatureFlag<FeatureFlagEnabled, number>;
  'mpw-strategic-seller-fee': FeatureFlag;
  'mpw-seller-cockpit': FeatureFlag;
  'mpw-filter-conversion': FeatureFlag;
  'mpw-remove-auction-from-room': FeatureFlag;
  'mpw-restrict-permissions-based-on-account-group': FeatureFlag<FeatureFlagEnabled, string>;
  'mpw-transfer-highest-bid': FeatureFlag;
  /**
   * INV flags
   */
  'inv-private-seller-complaint-terms': FeatureFlag<FeatureFlagEnabled, { url: string }>;
  'inv-inspection-request-mandatory-fields': FeatureFlag;
  'inv-appointment-dashboard': FeatureFlag;
  'inv-complimentary-cos-check-plus-for-high-priced-vehicles': FeatureFlag;
  /**
   * PNI flags
   */
  'pni-payment-overview-enabled': FeatureFlag;
  'pni-payment-overview-tab-count-enabled': FeatureFlag;
  'pni-manual-invoice-generator-enabled': FeatureFlag;
  'pni-new-checkout-page-enabled': FeatureFlag;
  'pni-nova-tax-dynamic-calculation-enabled': FeatureFlag;
  /**
   * EG flags
   */
  'eg-v2-user-signup-flow-fe': FeatureFlag;
  'eg-watchlist-added-event': FeatureFlag;
  'eg-show-last-viewed-section': FeatureFlag;
  'eg-last-searched-section': FeatureFlag;
  'eg-purchase-preference-section': FeatureFlag;
  /**
   * CRM flags
   */
  'crm-enable-running-auctions-api': FeatureFlag;
}
export type FeatureFlagKeys = keyof FeatureFlags;

/*
 * Tracking events that are under a feature flag
 * This is used to check if the feature flag is enabled before tracking the event
 * so we can evaluate the tracking plan implementation before enabling it on production
 */
const TRACKING_EVENTS_UNDER_EVALUATION: Partial<Record<AmplitudeTrackingEventKeys | 'testEventUnderEvaluation', FeatureFlagKeys>> = {
  /**
   * DO NOT USE: Used for testing purposes
   */
  testEventUnderEvaluation: 'test-flag',
  watchlistAdded: 'eg-watchlist-added-event',
};

/**
 * Default values for the feature flags
 * They are mandatory when accessing a non boolean feature flag
 */
export const FEATURE_FLAGS_DEFAULTS: Partial<FeatureFlags> = {
  'mpw-hot-bid-bidding-info-refresh-interval': {
    value: 'on',
    payload: 1,
  },
  'mpw-non-hot-bid-bidding-info-refresh-interval': {
    value: 'on',
    payload: 10,
  },
  'mpw-power-list-items-per-page': {
    value: 'on',
    payload: 20,
  },
  'mpw-restrict-permissions-based-on-account-group': {
    value: 'on',
    payload: '',
  },
};

@Injectable({
  providedIn: 'root',
})
export class ProductAnalyticsService {
  private amplitude: Amplitude;

  public constructor(
    heartbeatService: HeartbeatService,
    private coreClient: CosCoreClient,
    @Inject(AppEnvironment) environment: IAppEnvironment,
  ) {
    inject(NgZone).runOutsideAngular(() => (this.amplitude = new Amplitude(this.getUserUuid(), environment)));

    heartbeatService.oneSecondInterval$
      .pipe(
        takeEveryNth(FEATURE_FLAGS_REFRESH_INTERVAL_IN_SECONDS),
        tap(() => this.amplitude.refreshFeatureFlags()),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  /**
   * Identify the user to track events
   * @param userUuid The user uuid to identify
   * @returns void
   */
  public identifyUserByUuid(userUuid: string): void {
    this.amplitude.identifyUserByUuid(userUuid);
  }

  /**
   * Subscribe to feature flag is on
   * @param featureFlag The feature flag to check
   * @returns Observable of boolean
   */
  public isOn(featureFlag: FeatureFlagKeys): Observable<boolean> {
    return this.amplitude.isOn(featureFlag);
  }

  /**
   * Subscribe to feature flag is off
   * @param featureFlag The feature flag to check
   * @returns Observable of boolean
   */
  public isOff(featureFlag: FeatureFlagKeys): Observable<boolean> {
    return this.amplitude.isOff(featureFlag);
  }

  /**
   * Subscribe to feature flag value
   * @param featureFlag The feature flag to get the value of
   * @param fallbackValue The value to return if the feature flag is not defined or the request fails
   * @returns Observable of the feature flag
   */
  public getFeatureFlag<K extends FeatureFlagKeys>(featureFlag: K, fallback: FeatureFlags[K]): Observable<FeatureFlags[K]> {
    return this.amplitude.getFeatureFlag(featureFlag, fallback) as Observable<FeatureFlags[K]>;
  }

  public getSessionReplayUrl(): string {
    return this.amplitude.getSessionReplayUrl();
  }

  /**
   * Track an event
   * @param eventName The event name to track
   * @param payload The event payload
   */
  public trackEvent<T extends AmplitudeTrackingEventKeys>(eventName: T, payload?: AmplitudeTrackingEventPayload<T>): void {
    const events = this.amplitude.getTrackingEvents();
    const event = events[eventName] as (payload: AmplitudeTrackingEventPayload<T>) => unknown;

    if (!TRACKING_EVENTS_UNDER_EVALUATION[eventName]) {
      try {
        event.call(events, payload);
      } catch (error) {
        console.error('Error while tracking event', error);
      }
      return;
    }

    this.isOn(TRACKING_EVENTS_UNDER_EVALUATION[eventName])
      .pipe(
        filter(Boolean),
        take(1),
        tap(() => event.call(events, payload)),
        catchError(() => {
          console.error('Error while tracking event under evaluation');
          return of(null);
        }),
        timeout(300),
        catchError(() => {
          console.error('Tracking event under evaluation took too long to evaluate, event was not tracked');
          return of(null);
        }),
      )
      .subscribe();
  }

  /**
   * Set the current identified user properties
   */
  public setUserProperties(user: IGeneralUser): void {
    try {
      const userUuid = this.getUserUuid();
      const registrationState = user.account?.isPreregisteredAccount ? 'Pre-registered' : 'Registered';
      const userProperties: IdentifyProperties = {
        'Registration state': registrationState,
        Permissions: user.permissions,
        Roles: user.roles.map(role => role.replace(/{|}/gi, '')),
        'Active look and feel': LookAndFeelService.getLookAndFeelIdForUser(user),
      };

      this.amplitude.setUserProperties(userUuid, userProperties);
    } catch (error) {
      console.error('Error while setting user properties', error);
    }
  }

  private getUserUuid(): string {
    return this.coreClient.getLastAuthenticationResult().internalUserUUID;
  }
}
