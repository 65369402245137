import { AfterViewInit, booleanAttribute, Directive, ElementRef, Injector, Input, OnChanges, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, NgControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'enzo-textfield[showRequired], enzo-select[showRequired]',
})
export class ShowRequiredDirective implements AfterViewInit, OnChanges {
  public constructor(
    //
    private el: ElementRef,
    @Optional() private translateService: TranslateService, // in unit tests, it might be undefined
    private injector: Injector,
  ) {
    translateService?.onLangChange?.pipe(takeUntilDestroyed()).subscribe(() => this.ngOnChanges());
  }

  @Input({ transform: booleanAttribute })
  public showRequired: boolean = true;

  @Input({ transform: booleanAttribute })
  public required: boolean;

  private hasRequiredValidator: boolean = false;
  private initialized: boolean = false;
  private dummyEmptyFormControl = new FormControl(null);

  public ngOnChanges() {
    if (this.initialized) {
      if (this.showRequired) {
        this.el.nativeElement.hintText = this.translateService.instant(this.isRequired() ? 'general.required' : 'general.optional');
      } else {
        this.el.nativeElement.hintText = '';
      }
    }
  }

  private isRequired(): boolean {
    return this.required !== undefined ? this.required : this.hasRequiredValidator;
  }

  public ngAfterViewInit() {
    if (this.required === undefined) {
      const ngControl: NgControl | null = this.injector.get(NgControl, null, { optional: true });
      Promise.resolve().then(() => {
        if (ngControl?.control?.validator) {
          this.hasRequiredValidator = !!ngControl!.control!.validator(this.dummyEmptyFormControl)['required'];
          this.initialized = true;
          this.ngOnChanges();
        }
      });
    } else {
      this.initialized = true;
      this.ngOnChanges();
    }
  }
}
