import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError, map, tap, iif, delay, first, retryWhen, switchMap, take } from 'rxjs';

import { CountryCodeService, ErrorHandlers } from '@caronsale/frontend-services';
import {
  EAccountAugmentation,
  EAuctionState,
  IAccount,
  IAccountSettingsSeller,
  IAuction,
  IAuctionExpiryData,
  IAuctionFilter,
  ICompanyContactData,
  IFile,
  IGeneralBusinessTerms,
  IGPRInitialDATVehicleInformation,
  IGuaranteedPriceRequest,
  IIncident,
  IInvoice,
  IPage,
  IPaymentBalance,
  IPickupSlot,
  IRating,
  ISellerAccount,
  ISellerAuctionBidView,
  ISellerAuctionView,
  ISellerBankAccount,
  ISellerUser,
  ISellerUserStatistics,
  ISellerUserWithAccountBasicView,
  IServiceMetaData,
  ISurvey,
  ITradeInValuationRequest,
  ITradeInValuationResultDetails,
  Validation,
  ISurveyFilter,
  IGPRRefreshData,
  IVehiclePriceRequestLinkFilter,
  IVehiclePriceRequestLinkAugmentedWithGPRAndMPR,
  IPricingRangeResult,
} from '@caronsale/cos-models';
import { IVehicle, IVehicleConfiguration, IVehicleValuation } from '@caronsale/cos-vehicle-models';

import { IRenegotiationClient } from '@cosCoreServices/interfaces/renegotiation-client';
import { IClosedNoBidsClient } from '@cosCoreServices/interfaces/closed-no-bids-client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MediaUtils } from '../../utils/MediaUtils';
import { InspectionRequestRepository } from '@cosSeller/inspection-request/partials/state/inspection-request.repository';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';

// TODO: Rename to SellerClientService
@Injectable()
export class CosSellerClientService implements IClosedNoBidsClient, IRenegotiationClient {
  public sellerUserProfile: BehaviorSubject<ISellerUserWithAccountBasicView> = new BehaviorSubject<ISellerUserWithAccountBasicView>(null);

  public constructor(
    //
    private cosClient: CosCoreClient,
    private countryCodeSvc: CountryCodeService,
    private http: HttpClient,

    // TODO: remove these whenever the price and inspection request pages share a subroute
    private inspectionRequestRepository: InspectionRequestRepository,
  ) {}

  public getRunningAuctionsPage(filter?: IAuctionFilter): Observable<IPage<ISellerAuctionView>> {
    filter = filter || {};

    const userId = this.cosClient.getLastAuthenticationResult().internalUserUUID;
    const encodedParams = this.cosClient.encodeParamObject(filter);

    return this.cosClient.requestV2WithPrivileges('get', `/auction/seller/${userId}/running?filter=${encodedParams}`);
  }

  public getDraftedAuctionsPage(filter?: IAuctionFilter): Observable<IPage<ISellerAuctionView>> {
    filter = filter || {};

    const userId = this.cosClient.getLastAuthenticationResult().internalUserUUID;
    const encodedParams = this.cosClient.encodeParamObject(filter);

    return this.cosClient.requestV2WithPrivileges('get', `/auction/seller/${userId}/draft?filter=${encodedParams}`);
  }

  public getUnderReviewAuctionsPage(filter?: IAuctionFilter): Observable<IPage<ISellerAuctionView>> {
    filter = filter || {};

    const userId = this.cosClient.getLastAuthenticationResult().internalUserUUID;
    const encodedParams = this.cosClient.encodeParamObject(filter);

    return this.cosClient.requestV2WithPrivileges('get', `/auction/seller/${userId}/under-review?filter=${encodedParams}`);
  }

  public getToReinsertAuctionsList(filter?: IAuctionFilter): Observable<ISellerAuctionView[]> {
    filter = filter || {};

    const encodedParams = this.cosClient.encodeParamObject(filter);

    return this.cosClient.requestWithPrivileges('get', `/auction/dealership/ready-to-reinsert?filter=${encodedParams}`);
  }

  // alias name to implement the IRenegotiationClient interface
  public acceptOtherPartysOffer(auction: IAuction): Observable<void> {
    return this.confirmHighestBidForAuction(auction);
  }

  public confirmHighestBidForAuction(auction: IAuction): Observable<void> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('put', `/auction/dealership/${userId}/${auction.id}/sale`, auction);
  }

  public acceptMidpoint(auctionUuid: string): Observable<ISellerAuctionView> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('post', `/auction/dealership/${auctionUuid}/renegotiation-midpoint`);
  }

  // alias names for the IClosedNoBidsClient interface
  public disableAndDraftAgain(auction: IAuction): Observable<IAuction> {
    return this.rejectHighestBidForAuctionAndRestart(auction);
  }

  public disable(auction: IAuction): Observable<void> {
    return this.rejectHighestBidForAuctionAndDelete(auction);
  }

  public rejectHighestBidForAuctionAndDelete(auction: IAuction): Observable<void> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('delete', `/auction/dealership/${userId}/${auction.id}/sale`, auction);
  }

  public rejectHighestBidForAuctionAndRestart(auction: IAuction): Observable<IAuction> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('post', `/auction/dealership/${userId}/${auction.id}/sale`, auction);
  }

  public updateLastOfferForAuctionBelowMinAsk(auction: IAuction, newLastOffer: number): Observable<ISellerAuctionView> {
    return this.newOwnOffer(auction.uuid, newLastOffer);
  }

  public newOwnOffer(auctionUuid: string, newLastOffer: number): Observable<ISellerAuctionView> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('post', `/auction/dealership/${userId}/${auctionUuid}/last-offer?lastOffer=${newLastOffer}`);
  }

  public withdrawFromRenegotiation(auctionUuid: string): Observable<ISellerAuctionView> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;
    if (!userId) {
      return throwError({ status: 401 });
    }
    return this.cosClient.requestWithPrivileges('post', `/auction/dealership/${auctionUuid}/renegotiation-stop`);
  }

  public getAuctionsWaitingForPayment(filter?: IAuctionFilter): Observable<IPage<ISellerAuctionView>> {
    filter = filter || {};

    const userId = this.cosClient.getLastAuthenticationResult().userId;

    return this.cosClient.requestV2WithPrivileges('get', `/auction/seller/${userId}/waiting/payment?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getAuctionsWaitingForPickup(filter?: IAuctionFilter): Observable<IPage<ISellerAuctionView>> {
    filter = filter || {};

    const userId = this.cosClient.getLastAuthenticationResult().userId;

    return this.cosClient.requestV2WithPrivileges('get', `/auction/seller/${userId}/waiting/pickup?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getFinishedAuctions(filter?: IAuctionFilter): Observable<IPage<ISellerAuctionView>> {
    filter = filter || {};

    const userId = this.cosClient.getLastAuthenticationResult().userId;

    // TODO: Get rid?
    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestV2WithPrivileges('get', `/auction/seller/${userId}/finished?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public getDisabledAuctions(filter?: IAuctionFilter): Observable<IPage<ISellerAuctionView>> {
    filter = filter || {};

    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestV2WithPrivileges('get', `/auction/seller/${userId}/disabled?filter=${this.cosClient.encodeParamObject(filter)}`);
  }

  public confirmPickup(auction: IAuction): Observable<IAuction> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('post', `/auction/dealership/${userId}/${auction.id}/pickup`, {});
  }

  public startDraftedAuction(auction: IAuction): Observable<void> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    auction.state = EAuctionState.ACTIVE;

    // Move ending time

    return this.cosClient.requestWithPrivileges('post', `/auction/dealership/${userId}/draft/${auction.id}`, auction);
  }

  public updateDraftedAuction(auction: IAuction): Observable<void> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    // TODO: Do we really need this?
    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('post', `/auction/dealership/${userId}/draft/${auction.id}`, auction);
  }

  public reinsertAuction({ auctionUuid, minimumRequiredAsk }: { auctionUuid: string; minimumRequiredAsk: number }): Observable<IAuction> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError(() => ({ status: 401 }));
    }

    return this.cosClient.requestWithPrivileges('post', `/auction/dealership/${auctionUuid}/reinsert`, {
      newMinAskPrice: minimumRequiredAsk,
    });
  }

  public removeAuctionToReinsert(auctionUuid: string): Observable<IAuction> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError(() => ({ status: 401 }));
    }

    return this.cosClient.requestWithPrivileges('post', `/auction/dealership/${auctionUuid}/ready-to-reinsert/disable`);
  }

  public getAuction(auctionId: string): Observable<ISellerAuctionView> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('get', `/auction/dealership/${userId}/${auctionId}`);
  }

  public getAuctionsExpiringInTheNextDays(): Observable<Array<{ dateString: string; expiringAuctions: number }>> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('get', `/auction/dealership/${userId}/expiring`);
  }

  public getServiceMetaData(): Observable<IServiceMetaData> {
    return this.cosClient.request('get', `/meta`);
  }

  public getAvailableDatesForAuctions(): Observable<IAuctionExpiryData> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('get', `/auction/dealership/${userId}/days/available`);
  }

  public getValuationForVehicleByVin(vehicleToValuate: IVehicle): Observable<IVehicleValuation> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    if (Validation.isUndefinedOrNull(vehicleToValuate.vin)) {
      console.error(`Could not retrieve valuation for vehicle.`);
      return;
    }

    return this.cosClient.requestWithPrivileges('post', `/auction/dealership/${userId}/vehicle/${vehicleToValuate.vin}/valuation`, {
      ...vehicleToValuate,
      // Get rid of vehicle images, since they are not relevant for valuation (and slow down the request!)
      vehicleImages: null,
      equipmentData: null,
      damages: null,
    } as IVehicle);
  }

  public getAllBidsForAuction(auctionId: string): Observable<ISellerAuctionBidView[]> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('get', `/auction/dealership/${userId}/${auctionId}/bids`);
  }

  public reportIncidentForAuction(auction: IAuction, incident: IIncident): Observable<void> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('put', `/auction/dealership/${userId}/${auction.id}/incident`, incident);
  }

  public getSellerUserProfile(): Observable<ISellerUserWithAccountBasicView> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    // TODO: Check if authentication is available!

    return this.cosClient.requestWithPrivileges('get', `/profile/dealership/${userId}`);
  }

  public getAvailableSellerBankAccounts(): Observable<ISellerBankAccount[]> {
    return this.cosClient.requestWithPrivileges('get', `/seller/payment/bank-account/available`);
  }

  public getMySellerAccount(): Observable<ISellerAccount> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    return this.cosClient.requestWithPrivileges('get', `/profile/dealership/${userId}/seller-account`).pipe(
      tap((account: ISellerAccount) => {
        this.countryCodeSvc.userCountryCode = account.countryCode;
      }),
    );
  }

  public getMyAccount(augmentations?: EAccountAugmentation[]): Observable<IAccount> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    let requestPath = `/profile/dealership/${userId}/account`;

    if (augmentations?.length) {
      const encodedAugmentations = encodeURIComponent(augmentations.join(','));
      requestPath += `?augment=${encodedAugmentations}`;
    }

    return this.cosClient.requestWithPrivileges('get', requestPath);
  }

  public updateMyAccount(dataToUpdate: IAccount): Observable<void> {
    const userUuid = this.cosClient.getLastAuthenticationResult().internalUserUUID;

    return this.cosClient.requestWithPrivileges('put', `/profile/dealership/${userUuid}/account`, dataToUpdate);
  }

  public updateMyAccountSettingsSeller(dataToUpdate: IAccountSettingsSeller): Observable<void> {
    const userUuid = this.cosClient.getLastAuthenticationResult().internalUserUUID;

    return this.cosClient.requestWithPrivileges('put', `/profile/dealership/${userUuid}/account/settings/seller`, dataToUpdate);
  }

  public getMyLegalEntitySellerAccount(): Observable<ISellerAccount> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    return this.cosClient.requestWithPrivileges('get', `/profile/dealership/${userId}/seller-account/legal-entity`);
  }

  public getMyPaymentBalance(): Observable<IPaymentBalance> {
    return this.cosClient.requestWithPrivileges('get', `/seller/payment/balance`);
  }

  public getMyCompanyContact(): Observable<ICompanyContactData> {
    const userId = this.cosClient.getLastAuthenticationResult().internalUserId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('get', `/profile/dealership/${userId}/contact`);
  }

  public getGeneralBusinessTermsToAccept(): Observable<IGeneralBusinessTerms> {
    const userId = this.cosClient.getLastAuthenticationResult().internalUserId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('get', `/profile/dealership/${userId}/general-business-terms/to-accept`);
  }

  public acceptGeneralBusinessTerms(generalBusinessTerms: IGeneralBusinessTerms): Observable<IGeneralBusinessTerms> {
    const userId = this.cosClient.getLastAuthenticationResult().internalUserId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('post', `/profile/dealership/${userId}/general-business-terms/${generalBusinessTerms.id}`, undefined);
  }

  public getVehicleDataFromVin(vin: string): Observable<IVehicle> {
    return this.cosClient.requestWithPrivileges('get', `/auction/dealership/vehicle/${vin}`);
  }

  public retrieveDealershipStats(): Observable<ISellerUserStatistics> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('get', `/auction/dealership/${userId}/stats`);
  }

  public disableAuction(auctionToDisable: IAuction): Observable<any> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('delete', `/auction/dealership/${userId}/${auctionToDisable.id}`, undefined);
  }

  public createDraftedAuction(newAuction: IAuction): Observable<any> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('put', `/auction/dealership/${userId}/draft`, newAuction, this.cosClient.generateIdempotencyHeader());
  }

  public updateDealershipProfile(dealershipUserProfile: ISellerUser): Observable<any> {
    return this.cosClient.requestWithPrivileges('post', `/profile/dealership/${dealershipUserProfile.mailAddress}`, dealershipUserProfile);
  }

  public rateBuyerForAuction(auction: IAuction, rating: IRating): Observable<void> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('put', `/auction/dealership/${userId}/${auction.id}/rating`, rating);
  }

  public createMarketPriceRequest(mpr: ITradeInValuationRequest): Observable<void> {
    const userId = this.cosClient.getLastAuthenticationResult().internalUserId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('put', `/dealership/trade-in/valuation/${userId}`, mpr);
  }

  public getInvoicesForAuction(auctionId: string): Observable<IInvoice[]> {
    if (!auctionId) {
      return throwError({ status: 404 });
    }

    return this.cosClient.requestWithPrivileges('get', `/seller/invoices/auction/${auctionId}`);
  }

  public uploadTaxRegistrationDocument(document: IFile): Observable<IFile> {
    return this.cosClient.requestWithPrivileges('put', `/media/document/tax-registration`, document).pipe(
      map(res => {
        return {
          mimeType: null,
          rawData: null,
          encoding: null,
          url: res.url,
        } as IFile;
      }),
    );
  }

  public isReviewRequiredForAuction(auction: IAuction): Observable<any> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('put', `/auction/dealership/${userId}/review`, auction);
  }

  public getNextSurvey(filter?: ISurveyFilter): Observable<ISurvey> {
    const userUUID = this.cosClient.getLastAuthenticationResult().internalUserUUID;

    if (!userUUID) {
      return throwError({ status: 401 });
    }

    const encodedFilter: string = filter ? `?filter=${this.cosClient.encodeParamObject(filter)}` : '';

    return this.cosClient.requestWithPrivileges('get', `/survey/seller/${userUUID}/next${encodedFilter}`);
  }

  public captureSellerSurveyParticipation(survey: ISurvey): Observable<any> {
    const userUUID = this.cosClient.getLastAuthenticationResult().internalUserUUID;

    if (!userUUID) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('post', `/survey/seller/${userUUID}`, survey);
  }

  public requestVehicleReportParsing(vehicleReport: IFile): Observable<{ url: string }> {
    const userId = this.cosClient.getLastAuthenticationResult().userId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges('post', `/auction/dealership/${this.cosClient.getLastAuthenticationResult().userId}/vehicle-report`, {
      url: vehicleReport && vehicleReport.url,
    });
  }

  public getTradeInDataFromVin(vin: string): Observable<IVehicleConfiguration> {
    const userId = this.cosClient.getLastAuthenticationResult().internalUserId;
    if (!userId) {
      return throwError({ status: 401 });
    }
    return this.cosClient.requestWithPrivileges('get', `/dealership/trade-in/valuation/${userId}/configuration/${vin}`);
  }

  public markRequestAsSent(tradeInValuationIds: string[]): Observable<ITradeInValuationRequest> {
    return this.cosClient.requestWithPrivileges('post', `/dealership/trade-in/valuation/${tradeInValuationIds.join(',')}/mark-inspector-requested`);
  }

  public getVehiclePriceRequests(
    filter: IVehiclePriceRequestLinkFilter,
    fetchSubordinates: boolean,
  ): Observable<IPage<IVehiclePriceRequestLinkAugmentedWithGPRAndMPR>> {
    const userId = this.cosClient.getLastAuthenticationResult().internalUserId;

    if (!userId) {
      return throwError({ status: 401 });
    }

    return this.cosClient.requestWithPrivileges(
      'get',
      `/seller/vehicle-price-request?fetchSubordinateRequests=${fetchSubordinates}&filter=${this.cosClient.encodeParamObject(filter)}`,
    );
  }

  public getPriceRequestByUuid(uuid: string): Observable<IVehiclePriceRequestLinkAugmentedWithGPRAndMPR> {
    return this.getVehiclePriceRequests(
      {
        offset: 0,
        limit: 1,
        uuids: [uuid],
      },
      true,
    ).pipe(map(page => page.items?.[0]));
  }

  public getTradeInValuationDetails(tradeInValuationId: string): Observable<ITradeInValuationResultDetails> {
    const userUUID = this.cosClient.getLastAuthenticationResult().internalUserUUID;

    if (!userUUID) {
      return throwError({ status: 401 });
    }
    return this.cosClient.requestWithPrivileges('get', `/dealership/trade-in/valuation/${userUUID}/${tradeInValuationId}/details`);
  }

  public resetData(): Observable<void> {
    this.inspectionRequestRepository.resetPageFetch$.next();
    this.inspectionRequestRepository.resetInspectionState();
    return this.cosClient.requestWithPrivileges('delete', `/demo-environment/persistence-reset`);
  }

  public uploadVehicleReport(file: IFile): Observable<IFile> {
    return this.cosClient.requestWithPrivileges('put', '/media/vehicle/report', file).pipe(
      map(
        res =>
          <IFile>{
            mimeType: file.mimeType,
            rawData: file.rawData,
            encoding: null,
            url: res.url,
          },
      ),
    );
  }

  public getInitialDATVehicleInformation(vin: string): Observable<IGPRInitialDATVehicleInformation> {
    return this.cosClient.requestWithPrivileges('get', `/dealership/gpr/vehicleData/${vin}`);
  }

  public saveGuaranteedPriceRequest(guaranteedPriceRequest: IGuaranteedPriceRequest, submit: boolean = false): Observable<IGuaranteedPriceRequest> {
    return this.cosClient.requestWithPrivileges('put', `/dealership/gpr?submit=${submit}`, guaranteedPriceRequest);
  }

  public refreshGuaranteePrice(gprUuid: string, refreshData: IGPRRefreshData): Observable<IGuaranteedPriceRequest> {
    return this.cosClient.requestWithPrivileges('post', `/dealership/gpr/refresh/${gprUuid}`, refreshData);
  }

  public bookGuaranteedPriceAndRequestInspection(gprUuid: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', `/dealership/gpr/inspection/${gprUuid}`);
  }

  public downloadSellerPickupDocument(auction: ISellerAuctionView): void {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf').set('Cache-control', 'no-store');
    this.http
      .get(`${auction.urlToPickupSellerDocument}?v=${Date.now()}`, {
        headers,
        responseType: 'blob',
      })
      .subscribe((blob: Blob): void => {
        MediaUtils.downloadPDF(blob, `self-pickup-document-${auction.associatedVehicle.vin}.pdf`);
      });
  }

  public getBelowMinAskAuctions(): Observable<ISellerAuctionView[]> {
    return this.cosClient.requestWithPrivileges('get', `/auction/dealership/below-min-ask`);
  }

  public getSellerPickupSlots(): Observable<IPickupSlot[]> {
    return this.cosClient.requestWithPrivileges('get', '/accounts/pickup-slots');
  }

  public createSellerPickupSlot(slot: IPickupSlot): Observable<void> {
    return this.cosClient.requestWithPrivileges('post', '/accounts/pickup-slots', slot);
  }

  public updateSellerPickupSlot(slot: IPickupSlot): Observable<IPickupSlot> {
    return this.cosClient.requestWithPrivileges('put', `/accounts/pickup-slots/${slot.uuid}`, slot);
  }

  public removeSellerPickupSlot(uuid: string): Observable<void> {
    return this.cosClient.requestWithPrivileges('delete', `/accounts/pickup-slots/${uuid}`);
  }

  public getMinAskRangesForVins(vins: string[]): Observable<IPricingRangeResult> {
    const params = new URLSearchParams(vins.map(vin => ['vins', vin]));

    return this.cosClient.requestWithPrivileges('get', `/seller/vehicle-price-request/price-range?${params}`);
  }

  public getNotificatioPreferencesByUserId(userId: string, errorHandlers?: ErrorHandlers): Observable<{ config: Record<string, any> }> {
    return this.cosClient.requestWithPrivileges('get', `/notification-preferences/${userId}/SELLER`, undefined, undefined, errorHandlers);
  }

  public updateNotificatioPreferencesByUserId(userId: string, updatedPreferences, errorHandlers?: ErrorHandlers): Observable<any> {
    return this.cosClient.requestWithPrivileges('patch', `/notification-preferences/${userId}/SELLER`, updatedPreferences, undefined, errorHandlers);
  }

  public parseVehicleReport(report: File, url?: string): Observable<IVehicle> {
    const formData = new FormData();
    formData.append('file', report);

    const headers = { enctype: 'multipart/form-data' };

    return iif(
      () => !!url,
      this.cosClient.requestFromReportParsingWithPrivileges('post', `/v1/pdf/vehicle/async?url=${url}`, null) as Observable<{ jobId: string }>,
      this.cosClient.requestFromReportParsingWithPrivileges('post', `/v1/pdf/vehicle/async`, formData, headers) as Observable<{ jobId: string }>,
    ).pipe(
      delay(3000),
      switchMap((res: any) =>
        this.cosClient.requestFromReportParsingWithPrivileges('get', `/v1/pdf/vehicle/async/${res.jobId}`).pipe(
          map((val: any) => {
            if (val) {
              return val;
            }
            throw val;
          }),
          retryWhen(errors => errors.pipe(delay(3000), take(35))),
          first(),
        ),
      ),
    );
  }
}
