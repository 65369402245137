<app-expandable-panel [(expandedState)]="expandedState" (expandedStateChange)="expandedStateChange($event)">
  <div *ngIf="isRenegotiationCard" class="tax-info-title" panelTitle>
    <app-taxation-info [taxationInfo]="taxationInfo" [isRenegotiationCard]="isRenegotiationCard"></app-taxation-info>
  </div>
  <div *ngIf="!isRenegotiationCard" class="panel-title" panelTitle>
    <ng-container *ngIf="expandedState; else collapsedPanelTitle">
      <ng-container [ngTemplateOutlet]="expandedPanelTitle"></ng-container>
    </ng-container>
  </div>
  <div class="panel-content" panelContent>
    <ng-container *ngIf="isRenegotiationCard">
      <ng-container [ngTemplateOutlet]="expandedPanelTitle"></ng-container>
    </ng-container>
    <ng-container *ngIf="successFee">
      <enzo-text kind="label" align="left">{{ 'auction.success-fee' | translate }} ({{ 'general.net' | translate }})</enzo-text>
      <enzo-text kind="label" align="right">{{ successFee | currency: 'EUR' : 'symbol' : '1.0-0' }}</enzo-text>
    </ng-container>
    <ng-container *ngIf="transportationPrice && showPrebookedServicesFees">
      <enzo-text kind="label" align="left">{{ 'auction.transport-fee' | translate }} ({{ 'general.net' | translate }})</enzo-text>
      <enzo-text kind="label" align="right">{{ transportationPrice | currency: 'EUR' : 'symbol' : '1.0-0' }}</enzo-text>
    </ng-container>
    <ng-container *ngIf="cosCheckPlusGuaranteePrice && showPrebookedServicesFees">
      <enzo-text kind="label" align="left">{{ 'prebooked-service.guarantee.cos-check-plus-label' | translate }} ({{ 'general.net' | translate }})</enzo-text>
      <enzo-text kind="label" align="right">{{ cosCheckPlusGuaranteePrice | currency: 'EUR' : 'symbol' : '1.0-0' }}</enzo-text>
    </ng-container>
    <ng-container *ngIf="extendedGuaranteePrice && showPrebookedServicesFees">
      <enzo-text kind="label" align="left">{{ 'prebooked-service.guarantee.extended-label' | translate }} ({{ 'general.net' | translate }})</enzo-text>
      <enzo-text kind="label" align="right">{{ extendedGuaranteePrice | currency: 'EUR' : 'symbol' : '1.0-0' }}</enzo-text>
    </ng-container>
    <ng-container *ngIf="crossBorderTaxFee">
      <enzo-text kind="label" align="left">{{ 'auction.cross-border-fee' | translate }} ({{ 'general.net' | translate }})</enzo-text>
      <enzo-text kind="label" align="right">{{ crossBorderTaxFee | currency: 'EUR' : 'symbol' : '1.0-0' }}</enzo-text>
    </ng-container>
    <ng-container *ngIf="crossBorderTaxDiscount">
      <enzo-text kind="label" align="left">{{ 'auction.cross-border-discount' | translate }} ({{ 'general.net' | translate }})</enzo-text>
      <enzo-text kind="label" align="right" color="green">-{{ crossBorderTaxDiscount | currency: 'EUR' : 'symbol' : '1.0-0' }}</enzo-text>
    </ng-container>
    <ng-container *ngIf="selectedVoucher">
      <enzo-text kind="label" align="left">{{ 'voucher.voucher' | translate }}</enzo-text>
      <enzo-text kind="label" align="right" color="green">{{ applicableVoucherAmount | currency: 'EUR' : 'symbol' : '1.0-0' }}</enzo-text>
    </ng-container>
    <ng-container [ngTemplateOutlet]="collapsedPanelTitle"></ng-container>
  </div>
</app-expandable-panel>

<ng-template #expandedPanelTitle>
  <enzo-text kind="label" align="left">{{ 'auction.purchase-fee' | translate }} ({{ 'general.net' | translate }})</enzo-text>
  <enzo-text kind="label" align="right">{{ purchaseFee | currency: 'EUR' : 'symbol' : '1.0-0' }}</enzo-text>
</ng-template>

<ng-template #collapsedPanelTitle>
  <enzo-text kind="body-bold">{{ 'auction.total-amount' | translate }}</enzo-text>
  <enzo-text kind="body-bold" align="right">{{ totalAmount | currency: 'EUR' : 'symbol' : '1.0-0' }}</enzo-text>
</ng-template>
