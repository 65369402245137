import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Icon } from '@caronsale/enzo';

@Component({
  selector: 'enzo-tag',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  standalone: true,
  host: {
    '[icon]': 'icon()',
    '[kind]': 'kind()',
    '[label]': 'label()',
  },
})
export class EnzoTagComponent {
  public icon = input<Icon>();
  public kind = input<'normal' | 'highlight' | 'success' | 'warning' | 'danger'>('normal');
  public label = input<string>('');
}
