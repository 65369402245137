import { Component, inject, signal } from '@angular/core';
import {
  BuyerAuctionService,
  EBuyerAuctionTabId,
  IBuyerAuctionList,
  IS_POLLING,
  TBuyerAuctionListFunctionName,
} from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { EAccountAugmentation, ESellerBusinessType, FilterUtils, IAuctionFilter, IAuctionRoom, IBuyerActivationTaskList } from '@caronsale/cos-models';
import { BASE_AUCTION_FILTER, BuyerAuctionSearchService } from '@cosBuyer/auctions/partials/auction-search/auction-search.service';
import { REQ_INTERVAL } from '@cosCoreConfig/RequestIntervalConfig';
import { HeartbeatService, takeEveryNth } from '@caronsale/frontend-services';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  from,
  map,
  mergeWith,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  tap,
  UnaryFunction,
} from 'rxjs';
import { IBuyerCockpitActivityCard } from './partials/cockpit-activity-cards/cockpit-activity-cards.component';
import { CosBuyerClientService, LAST_VIEWED_DEFAULT_LIMIT } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { mapBuyerActivationTasksToActivityCards } from './partials/cockpit-activity-cards/cockpit-activity-cards-config';
import { Router } from '@angular/router';
import { AccountDataService } from '@cosCoreServices/account-data/account-data.service';
import { GoogleAnalyticsService } from '@cosCoreServices/google-analytics/google-analytics.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { differenceInSeconds } from 'date-fns';
import { EFuelType } from '@caronsale/cos-vehicle-models';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { AuctionDetailsViewedProperties } from '@cosCoreServices/product-analytics/amplitude/ampli';
import { TrackFormControlOrigin } from '@cosBuyer/directives/track-form-control.directive';
import { EPartnerName, LookAndFeelService } from '@cosBuyer/services/look-and-feel/look-and-feel.service';
import { EAdvertisementTypes } from '@cosBuyer/auctions/room/EAdvertisementType';
import { I18nMileageOrUnknownPipe } from '@caronsale/frontend-pipes';
import { AuctionFilterUtils, IFilterChip } from '@cosBuyer/auctions/services/auction-filter-utils';
import { TranslateService } from '@ngx-translate/core';

const SUBTITLE_MAX_LENGTH = 120;

export const SECTION_AUCTION_FILTER = {
  ...FilterUtils.createAuctionFilter(),
  limit: 11,
  offset: 0,
};

export enum EBuyerCockpitAuctionListType {
  Discount = 'discount',
  LastViewed = 'last-viewed',
  LastSearched = 'last-searched',
  PurchasePreference = 'purchase-preference',
  Recommended = 'recommended',
  Watchlist = 'watchlist',
  Running = 'running',
  ElectricVehicle = 'electric-vehicle',
  HighValueVehicle = 'highvalue-vehicle',
  PrivateVehicle = 'private-vehicle',
}

const DEFAULT_ACTIVITY_CARD: IBuyerCockpitActivityCard = {
  type: 'checkAuctions',
  params: {
    numAuctions: '0',
  },
};

const BASE_BUYER_AUCTION_LIST: IBuyerAuctionList = { totalCount: 0, auctionUuids: [] };

const getAuctionsListCatchError: UnaryFunction<Observable<IBuyerAuctionList>, Observable<IBuyerAuctionList>> = catchError(() => of(BASE_BUYER_AUCTION_LIST));

const AUCTION_DETAILS_VIEWED_TRACKING_CATEGORIES: Record<EBuyerCockpitAuctionListType, AuctionDetailsViewedProperties['Auction category']> = {
  [EBuyerCockpitAuctionListType.Discount]: 'Discounted auctions',
  [EBuyerCockpitAuctionListType.LastViewed]: 'Last viewed auctions',
  [EBuyerCockpitAuctionListType.LastSearched]: 'Last searched auctions',
  [EBuyerCockpitAuctionListType.PurchasePreference]: 'Purchase preference auctions',
  [EBuyerCockpitAuctionListType.Recommended]: 'Recommended auctions',
  [EBuyerCockpitAuctionListType.Watchlist]: 'On watchlist',
  [EBuyerCockpitAuctionListType.Running]: 'Recent auctions',
  [EBuyerCockpitAuctionListType.ElectricVehicle]: 'Electric vehicles',
  [EBuyerCockpitAuctionListType.HighValueVehicle]: 'High-value vehicles',
  [EBuyerCockpitAuctionListType.PrivateVehicle]: 'Private vehicles',
};

@Component({
  selector: 'app-cockpit',
  templateUrl: './cockpit.component.html',
  styleUrls: ['./cockpit.component.scss'],
  providers: [{ provide: TrackFormControlOrigin, useValue: 'Cockpit' }],
})
export class CockpitComponent {
  private i18nMileageOrUnknownPipe = inject(I18nMileageOrUnknownPipe);
  private translateService = inject(TranslateService);

  private LAST_SEARCH_AUCTIONS_FILTER$ = new BehaviorSubject<IAuctionFilter>({ ...SECTION_AUCTION_FILTER });
  public LAST_SEARCH_AUCTIONS_FILTER = toSignal(this.LAST_SEARCH_AUCTIONS_FILTER$);
  public lastSearchFilterSubtitle = toSignal(
    combineLatest([this.translateService.onLangChange.pipe(startWith(null)), this.LAST_SEARCH_AUCTIONS_FILTER$.asObservable()]).pipe(
      map(([_, filter]) => this.buildFilterSubtitle(filter)),
    ),
  );
  public lastSearchAuctionDetailsUrlTemplate = signal<string>(
    this.buyerAuctionSearchService.getAuctionDetailsUrlTemplateWithAuctionFilterParams(
      { ...this.LAST_SEARCH_AUCTIONS_FILTER(), limit: BASE_AUCTION_FILTER.limit },
      EBuyerAuctionTabId.RUNNING_AUCTIONS,
    ),
  );

  private PURCHASE_PREFERENCE_AUCTIONS_FILTER$ = new BehaviorSubject<IAuctionFilter>({ ...SECTION_AUCTION_FILTER });
  public PURCHASE_PREFERENCE_AUCTIONS_FILTER = toSignal(this.PURCHASE_PREFERENCE_AUCTIONS_FILTER$);
  public purchasePreferenceFilterSubtitle = toSignal(
    combineLatest([this.translateService.onLangChange.pipe(startWith(null)), this.PURCHASE_PREFERENCE_AUCTIONS_FILTER$.asObservable()]).pipe(
      map(([_, filter]) => this.buildFilterSubtitle(filter)),
    ),
  );
  public purchasePreferenceAuctionDetailsUrlTemplate = signal<string>(
    this.buyerAuctionSearchService.getAuctionDetailsUrlTemplateWithAuctionFilterParams(
      { ...this.PURCHASE_PREFERENCE_AUCTIONS_FILTER(), limit: BASE_AUCTION_FILTER.limit },
      EBuyerAuctionTabId.RUNNING_AUCTIONS,
    ),
  );

  public readonly PRIVATE_AUCTIONS_FILTER: IAuctionFilter = {
    ...SECTION_AUCTION_FILTER,
    sellerAccounts: {
      sellerTypes: [ESellerBusinessType.PRIVATE],
    },
  };

  public readonly COCKPIT_LIST_TYPE = EBuyerCockpitAuctionListType;

  public readonly ELECTRIC_VEHICLES_AUCTIONS_FILTER: IAuctionFilter = {
    ...SECTION_AUCTION_FILTER,
    vehicleSearchQuery: {
      fuelTypes: [EFuelType.ELECTRIC],
    },
  };

  public readonly MERCEDES_BENZ_AUCTIONS_FILTER: IAuctionFilter = {
    ...SECTION_AUCTION_FILTER,
    advertisementTypes: [EAdvertisementTypes.MercedesBenz],
  };

  public readonly MIN_PRICE_FOR_HIGH_VALUE_VEHICLES = 20000;
  public readonly HIGH_VALUE_VEHICLES_AUCTIONS_FILTER: IAuctionFilter = {
    ...SECTION_AUCTION_FILTER,
    currentPriceFrom: this.MIN_PRICE_FOR_HIGH_VALUE_VEHICLES,
  };

  public readonly DISCOUNT_AUCTIONS_FILTER: IAuctionFilter = {
    ...SECTION_AUCTION_FILTER,
    hasDiscounts: true,
  };

  public readonly LAST_VIEWED_AUCTIONS_FILTER: IAuctionFilter = {
    ...SECTION_AUCTION_FILTER,
    limit: LAST_VIEWED_DEFAULT_LIMIT,
  };

  public readonly recommendedAuctionDetailsUrlTemplate: string = this.buyerAuctionSearchService.getAuctionDetailsUrlTemplateWithAuctionFilterParams(
    BASE_AUCTION_FILTER,
    EBuyerAuctionTabId.RECOMMENDED_AUCTIONS,
  );

  public readonly watchlistAuctionDetailsUrlTemplate: string = this.buyerAuctionSearchService.getAuctionDetailsUrlTemplateWithAuctionFilterParams(
    BASE_AUCTION_FILTER,
    EBuyerAuctionTabId.WATCHLIST,
  );

  public readonly runningAuctionDetailsUrlTemplate: string = this.buyerAuctionSearchService.getAuctionDetailsUrlTemplateWithAuctionFilterParams(
    BASE_AUCTION_FILTER,
    EBuyerAuctionTabId.RUNNING_AUCTIONS,
  );

  public readonly runningPrivateAuctionDetailsUrlTemplate: string = this.buyerAuctionSearchService.getAuctionDetailsUrlTemplateWithAuctionFilterParams(
    { ...this.PRIVATE_AUCTIONS_FILTER, limit: BASE_AUCTION_FILTER.limit },
    EBuyerAuctionTabId.RUNNING_AUCTIONS,
  );

  public readonly runningElectricVehiclesAuctionDetailsUrlTemplate: string = this.buyerAuctionSearchService.getAuctionDetailsUrlTemplateWithAuctionFilterParams(
    { ...this.ELECTRIC_VEHICLES_AUCTIONS_FILTER, limit: BASE_AUCTION_FILTER.limit },
    EBuyerAuctionTabId.RUNNING_AUCTIONS,
  );

  public readonly runningMercedesBenzAuctionDetailsUrlTemplate: string = this.buyerAuctionSearchService.getAuctionDetailsUrlTemplateWithAuctionFilterParams(
    { ...this.MERCEDES_BENZ_AUCTIONS_FILTER, limit: BASE_AUCTION_FILTER.limit },
    EBuyerAuctionTabId.RUNNING_AUCTIONS,
  );

  public readonly runningHighValueVehiclesAuctionDetailsUrlTemplate: string =
    this.buyerAuctionSearchService.getAuctionDetailsUrlTemplateWithAuctionFilterParams(
      { ...this.HIGH_VALUE_VEHICLES_AUCTIONS_FILTER, limit: BASE_AUCTION_FILTER.limit },
      EBuyerAuctionTabId.RUNNING_AUCTIONS,
    );

  public readonly runningDiscountAuctionDetailsUrlTemplate: string = this.buyerAuctionSearchService.getAuctionDetailsUrlTemplateWithAuctionFilterParams(
    { ...this.DISCOUNT_AUCTIONS_FILTER, limit: BASE_AUCTION_FILTER.limit },
    EBuyerAuctionTabId.RUNNING_AUCTIONS,
  );

  public readonly lastViewedAuctionDetailsUrlTemplate: string = this.buyerAuctionSearchService.getAuctionDetailsUrlTemplateWithAuctionFilterParams(
    this.LAST_VIEWED_AUCTIONS_FILTER,
    EBuyerAuctionTabId.RUNNING_AUCTIONS,
  );

  private refreshCards$ = new Subject<void>();

  public auctionRooms$ = this.heartbeatService.oneSecondInterval$.pipe(
    takeEveryNth(REQ_INTERVAL.BUYER_USER.FETCH_RUNNING_AUCTIONS_LIST_IN_SECONDS),
    startWith(0),
    switchMap(() => this.cosBuyerClientService.getAuctionRooms(IS_POLLING)),
    takeUntilDestroyed(),
  );

  public runningAuctions$ = this.createAuctionListObservable('getRunningAuctionsPage').pipe(shareReplay({ refCount: true }));
  public recommendedAuctions$ = this.createAuctionListObservable('getRecommendedAuctions');
  public watchlistAuctions$ = this.createAuctionListObservable('getWatchlistAuctions');
  public privateAuctions$ = this.createAuctionListObservable('getRunningAuctionsPage', this.PRIVATE_AUCTIONS_FILTER);
  public electricVehiclesAuctions$ = this.createAuctionListObservable('getRunningAuctionsPage', this.ELECTRIC_VEHICLES_AUCTIONS_FILTER);
  public mercedesBenzAuctions$ = this.createAuctionListObservable('getRunningAuctionsPage', this.MERCEDES_BENZ_AUCTIONS_FILTER);
  public highValueVehiclesAuctions$ = this.createAuctionListObservable('getRunningAuctionsPage', this.HIGH_VALUE_VEHICLES_AUCTIONS_FILTER);
  public discountAuctions$ = this.createAuctionListObservable('getRunningAuctionsPage', this.DISCOUNT_AUCTIONS_FILTER);
  public lastViewedAuctions$ = this.createAuctionListObservable('getLastViewedAuctions', this.LAST_VIEWED_AUCTIONS_FILTER);
  public lastSearchedAuctions$ = this.createAuctionListObservable('getLastSearchedAuctions').pipe(tap(result => this.setLastSearchedFilters(result.filter)));
  public purchasePreferenceAuctions$ = this.createAuctionListObservable('getPurchasePreferenceAuctions').pipe(
    tap(result => this.setPurchasePreferenceFilters(result.filter)),
  );

  public strategicPartnerFeatureFlag = toSignal(this.productAnalyticsService.isOn('mpw-strategic-seller-fee'));

  public activityCards$: Observable<IBuyerCockpitActivityCard[]> = this.heartbeatService.oneSecondInterval$.pipe(
    takeEveryNth(REQ_INTERVAL.BUYER_USER.ACTIVITY_CARDS_REFRESH_INTERVAL_IN_SECONDS),
    startWith(0),
    mergeWith(this.refreshCards$),
    switchMap(() =>
      this.accountDataService
        .getAccountData([EAccountAugmentation.SETTINGS_BUYER])
        .pipe(map(accountData => Boolean(accountData.settingsBuyer?.kycRequestedAt) && accountData.isPreregisteredAccount)),
    ),
    switchMap(() => this.cosBuyerClientService.getCockpitActivityCardData2()),
    map((activities: IBuyerActivationTaskList) => mapBuyerActivationTasksToActivityCards(activities)),
    switchMap(cards => {
      if (cards?.length) {
        return of(cards);
      }

      return this.runningAuctions$.pipe(
        map(runningAuctions => [
          {
            ...DEFAULT_ACTIVITY_CARD,
            params: { numAuctions: String(runningAuctions?.totalCount || 0) },
          },
        ]),
      );
    }),
    takeUntilDestroyed(),
  );

  public EBuyerAuctionTabId: typeof EBuyerAuctionTabId = EBuyerAuctionTabId;
  public EPartnerName = EPartnerName;

  public searchRevampFeatureFlag = toSignal(this.productAnalyticsService.isOn('mpw-search-revamp'));
  public showLastViewedSectionFeatureFlag = toSignal(this.productAnalyticsService.isOn('eg-show-last-viewed-section'));
  public showLastSearchedSectionFeatureFlag = toSignal(this.productAnalyticsService.isOn('eg-last-searched-section'));
  public showPurchasePreferenceFeatureFlag = toSignal(this.productAnalyticsService.isOn('eg-purchase-preference-section'));

  public selectedLookAndFeel$ = this.lookAndFeelService.selectedLookAndFeel$.pipe(
    map(({ bannerGradient, partnerLogo, partnerName, visibleAdvertisementTypes }) => ({ bannerGradient, partnerLogo, partnerName, visibleAdvertisementTypes })),
  );

  public constructor(
    private buyerAuctionService: BuyerAuctionService,
    private cosBuyerClientService: CosBuyerClientService,
    private heartbeatService: HeartbeatService,
    private buyerAuctionSearchService: BuyerAuctionSearchService,
    private accountDataService: AccountDataService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private productAnalyticsService: ProductAnalyticsService,
    private lookAndFeelService: LookAndFeelService,
  ) {}

  public trackByUuid(_, auctionUuid: string) {
    return auctionUuid;
  }

  public trackByAuctionRoomUuid(_, auctionRoom: IAuctionRoom) {
    return auctionRoom.uuid;
  }

  public handleShowMoreClick(tabId: EBuyerAuctionTabId, auctionFilter?: IAuctionFilter, auctionType?: EBuyerCockpitAuctionListType): void {
    this.googleAnalyticsService.trackBuyerCockpitSectionGoToAuctionsClick(tabId, auctionType);
    from(
      this.router.navigateByUrl(
        this.buyerAuctionSearchService.getAuctionOverviewUrlWithAuctionFilterParams(
          {
            ...auctionFilter,
          },
          tabId,
        ),
      ),
    ).subscribe(() => window.scrollTo(0, 0));
  }

  public refreshActivityCards(): void {
    this.refreshCards$.next();
  }

  public trackCardClick(listType: EBuyerCockpitAuctionListType, auctionUuid: string): void {
    this.googleAnalyticsService.trackBuyerCockpitCardClick(listType);
    this.productAnalyticsService.trackEvent('auctionDetailsViewed', {
      'Auction category': AUCTION_DETAILS_VIEWED_TRACKING_CATEGORIES[listType],
      'Auction origin': 'Cockpit',
      'Auction uuid': auctionUuid,
    });
  }

  public hasMBAdvertisementType(adTypes: EAdvertisementTypes[]) {
    return adTypes.includes(EAdvertisementTypes.MercedesBenz);
  }

  private setLastSearchedFilters(filter: IAuctionFilter) {
    const updatedFilter = { ...SECTION_AUCTION_FILTER, ...filter };
    this.LAST_SEARCH_AUCTIONS_FILTER$.next(filter);
    this.lastSearchAuctionDetailsUrlTemplate.set(
      this.buyerAuctionSearchService.getAuctionDetailsUrlTemplateWithAuctionFilterParams(
        { ...updatedFilter, limit: BASE_AUCTION_FILTER.limit },
        EBuyerAuctionTabId.RUNNING_AUCTIONS,
      ),
    );
  }

  private setPurchasePreferenceFilters(filter: IAuctionFilter) {
    const updatedFilter = { ...SECTION_AUCTION_FILTER, ...filter };
    this.PURCHASE_PREFERENCE_AUCTIONS_FILTER$.next(filter);
    this.purchasePreferenceAuctionDetailsUrlTemplate.set(
      this.buyerAuctionSearchService.getAuctionDetailsUrlTemplateWithAuctionFilterParams(
        { ...updatedFilter, limit: BASE_AUCTION_FILTER.limit },
        EBuyerAuctionTabId.RUNNING_AUCTIONS,
      ),
    );
  }

  private createAuctionListObservable(
    service: TBuyerAuctionListFunctionName,
    itemFilter: IAuctionFilter = SECTION_AUCTION_FILTER,
  ): Observable<IBuyerAuctionList> {
    let auctionsUuids: string[] = [];
    let lastRefreshDate: Date;
    let busy = false;

    return this.heartbeatService.oneSecondInterval$.pipe(
      filter(() => differenceInSeconds(new Date(), lastRefreshDate) > REQ_INTERVAL.BUYER_USER.FETCH_RUNNING_AUCTIONS_LIST_IN_SECONDS && !busy),
      startWith(0),
      tap(() => (busy = true)),
      mergeWith(this.buyerAuctionService.auctionBuyerClosedForListViews$.pipe(filter(auctionUuid => auctionsUuids.includes(auctionUuid)))),
      switchMap(() => this.buyerAuctionService.getAuctionList(service, itemFilter, IS_POLLING).pipe(getAuctionsListCatchError)),
      tap(auctionList => {
        auctionsUuids = auctionList.auctionUuids;
        lastRefreshDate = new Date();
        busy = false;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      takeUntilDestroyed(),
    );
  }

  private buildFilterSubtitle(filter: IAuctionFilter): string {
    const customFilterOrder: Array<IFilterChip['field']> = ['vehicleSearchQuery.makeAndModelPairs'];
    const subtitle = AuctionFilterUtils.convertFilterToChips(this.buyerAuctionSearchService.removeLimitAndOffset(filter), undefined, {
      i18nMileageOrUnknownPipe: this.i18nMileageOrUnknownPipe,
    })
      .sort((a, b) => {
        const index1 = customFilterOrder.indexOf(a.field);
        const index2 = customFilterOrder.indexOf(b.field);
        return (index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity);
      })
      .map(chip => (chip.valueTranslationKey ? this.translateService.instant(chip.valueTranslationKey, chip.valueTranslationParams) : chip.rawValue))
      .join(' • ');

    return subtitle.length > SUBTITLE_MAX_LENGTH ? `${subtitle.slice(0, SUBTITLE_MAX_LENGTH - 3)}...` : subtitle;
  }
}
