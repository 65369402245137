import { Directive } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessor } from './value-accessor';

@Directive({
  /* eslint-disable-next-line @angular-eslint/directive-selector */
  selector: 'enzo-select, enzo-radio-button-group',
  host: {
    '(enzoInput)': '_handleChangeEvent($event)',
    '(touchChanged)': '_handleTouchChangedEvent($event)',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectValueAccessorDirective,
      multi: true,
    },
  ],
})
export class SelectValueAccessorDirective extends ValueAccessor {
  public _handleChangeEvent({ detail }) {
    if (detail.source === 'Select') {
      super.handleChangeEvent(detail.allowMultiple ? detail.selectedOptions : detail.selectedOption);
    } else {
      // radio group
      super.handleChangeEvent(detail.value);
    }
  }
  public _handleTouchChangedEvent(event: any) {
    if (event.detail) {
      this.onTouched();
    }
  }
  public writeValue(value: any) {
    Promise.resolve().then(() => (this.el.nativeElement.value = this.lastValue = value));
  }
}
