import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Color } from '@caronsale/enzo';

@Component({
  selector: 'enzo-badge',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  standalone: true,
  host: {
    '[color]': 'color()',
    '[label]': 'label()',
  },
})
export class EnzoBadgeComponent {
  public color = input<Color>('grey');
  public label = input<string>('');
}
