import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'enzo-loader',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  standalone: true,
  host: {
    '[color]': 'color()',
    '[size]': 'size()',
  },
})
export class EnzoLoaderComponent {
  public color = input<'light' | 'dark' | 'inherit'>('dark');
  public size = input<'small' | 'medium' | 'large'>('medium');
}
