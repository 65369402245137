import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { EnzoIcon } from '@caronsale/enzo';

@Component({
  selector: 'enzo-banner',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  standalone: true,
  host: {
    '[icon]': 'icon()',
    '[kind]': 'kind()',
    '[label]': 'label()',
  },
})
export class EnzoBannerComponent {
  public icon = input<EnzoIcon['icon'] | 'default'>('default');
  public kind = input<'neutral' | 'highlight' | 'success' | 'warning' | 'danger'>('neutral');
  public label = input<string>('');
}
