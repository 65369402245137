import { Injectable } from '@angular/core';
import { FilterUtils, ICustomerCommunicationEvent, IGeneralUserFilter, IInternalBuyerView, IPage } from '@caronsale/cos-models';
import { BehaviorSubject, Observable } from 'rxjs';
import { CosInternalClientService } from '@cosCoreServices/cos-internal-client/cos-internal-client.service';
import { CosAuthService } from '@cosCoreServices/cos-auth/cos-auth.service';

@Injectable()
export class InternalBuyerWhatsappClientService {
  public salesman: BehaviorSubject<IInternalBuyerView> = new BehaviorSubject<IInternalBuyerView>(null);

  public automaticMessages: BehaviorSubject<ICustomerCommunicationEvent[]> = new BehaviorSubject<ICustomerCommunicationEvent[]>(null);

  public buyersAvailable: BehaviorSubject<IInternalBuyerView[]> = new BehaviorSubject<IInternalBuyerView[]>(null);

  public unreadMessages: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public filterRequest: IGeneralUserFilter;

  public constructor(
    private internalCosClient: CosInternalClientService,
    private authService: CosAuthService,
  ) {
    this.filterRequest = FilterUtils.createGeneralUserFilter();
  }

  public getBuyers(filter: IGeneralUserFilter): Observable<IPage<IInternalBuyerView>> {
    return this.internalCosClient.getBuyerUsersPage(filter);
  }

  public setTotalUnreadMessages(buyers: IInternalBuyerView[]) {
    return buyers.reduce((a, b) => a + (b.recentMessages.length || 0), 0);
  }

  public getBuyersAvailableForWhatsapp() {
    this.getBuyers(this.filterRequest).subscribe(buyers => {
      this.buyersAvailable.next(buyers.items);
      this.unreadMessages.next(this.setTotalUnreadMessages(buyers.items));
    });
  }
}
