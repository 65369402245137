import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

export declare type EnzoValidationError = {
  messageKey: string;
  translationParams?: Record<string, any>;
  type?: 'error' | 'incomplete';
};

export class EnzoValidation {
  public static wrapValidator(validatorFn: ValidatorFn, messageKey: string, translationParams?: { [key: string]: any }, type?: string): ValidatorFn {
    return (control: AbstractControl) => {
      const error = validatorFn(control);
      if (error) {
        const errorKey = Object.keys(error)[0];
        const errorValue = error[errorKey];
        if (typeof errorValue === 'object') {
          errorValue.messageKey = messageKey;
          errorValue.translationParams = translationParams;
        } else {
          error[errorKey] = { value: errorValue, messageKey, translationParams };
        }
        if (type) {
          error[errorKey].type = type;
        }
      }
      return error;
    };
  }

  public static updateAllValueAndValidity(control: AbstractControl): void {
    if (control instanceof FormControl) {
      control.updateValueAndValidity({ onlySelf: true });
    }
    if (control instanceof FormArray) {
      control.controls.forEach(control => this.updateAllValueAndValidity(control));
      control.updateValueAndValidity();
    }
    if (control instanceof FormGroup) {
      Object.values(control.controls).forEach(control => this.updateAllValueAndValidity(control));
      control.updateValueAndValidity();
    }
  }
}
