import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  fetchMFAPreference,
  getCurrentUser,
  resetPassword,
  setUpTOTP,
  signIn,
  signOut,
  updateMFAPreference,
  updatePassword,
  verifyTOTPSetup,
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import {
  EAuthenticationChallenge,
  EPublicEnvironmentConfigKeys,
  EUserType,
  IAuction,
  IAuctionsConfig,
  IAuthenticationResult,
  IBusinessDataConfig,
  IBusinessState,
  ICustomerLanguage,
  IGeneralUser,
  IServiceMetaData,
  IUserInfo,
  IUserProfile,
  IUsersConfig,
  Validation,
} from '@caronsale/cos-models';
import { BehaviorSubject, defer, finalize, from, iif, Observable, of, retry, throwError, catchError, filter, map, shareReplay, switchMap, tap } from 'rxjs';
import {
  EVehicleType,
  IVehicleBaseModel,
  IVehicleEngineOption,
  IVehicleGearingOption,
  IVehicleImage,
  IVehicleMake,
  IVehicleSubModel,
} from '@caronsale/cos-vehicle-models';
import { AppEnvironment, IAppEnvironment } from '@cosCoreEnvironments/IAppEnvironment';
import { Amplify } from 'aws-amplify';

export type HookHandler = () => void;
export type AuthHookHandler = (authResult: IAuthenticationResult) => void;
export type ErrorHandlers = Record<number, HookHandler>;

/**
 * AWS's amplify user tokens
 */
interface IUserTokens {
  accessToken: string;
  idToken: string;
}

// unfortunately not exported from aws-amplify/auth
export const USER_ALREADY_AUTHENTICATED_EXCEPTION = 'UserAlreadyAuthenticatedException';

const NoHandler: HookHandler = () => {
  //
};

@Injectable({
  providedIn: 'root',
})
export class CosCoreClient {
  private backendApiBaseUrl: string = this.environment.backendUrl;
  private backendV2ApiBaseUrl: string = this.environment.backendUrl.replace('v1', 'v2');
  private vehicleReportParsingServiceUrl: string =
    this.environment.vehicleReportParsingServiceUrl || 'https://vehicle-report-parsing-srv-dev.herokuapp.com/api';

  /**
   * helper if the user object if the user is not authenticated
   */
  private readonly notAuthenticated: IAuthenticationResult = {
    authenticated: false,
    userId: null,
    internalUserId: null,
    internalUserUUID: null,
    token: null,
    type: null,
    privileges: null,
    userRole: null,
  };

  /**
   * helper if the user is authenticated but not confirmed yet
   */
  private readonly authenticatedButNotConfirmed: IAuthenticationResult = {
    authenticated: true,
    userId: null,
    internalUserId: null,
    internalUserUUID: null,
    token: null,
    type: null,
    privileges: null,
    userRole: null,
  };

  private successfulAuthenticationHandler: AuthHookHandler = NoHandler;
  private unauthorizedHandler: HookHandler = NoHandler;
  private sessionExpiredHandler: HookHandler = NoHandler;
  private notFoundHandler: HookHandler = NoHandler;
  private updateConflictHandler: HookHandler = NoHandler;
  private noConnectionHandler: HookHandler = NoHandler;
  private serverErrorHandler: HookHandler = NoHandler;
  private serviceNotAvailableHandler: HookHandler = NoHandler;
  private bidProhibitedErrorHandler: HookHandler = NoHandler;

  private lastAuthenticationResult: IAuthenticationResult;

  private userProfiles: IUserProfile[];
  private availableUserTypes: EUserType[];
  private selectedUserType: EUserType;
  private generalUserSubject: BehaviorSubject<IGeneralUser> = new BehaviorSubject<IGeneralUser>(null);
  /**
   * Observable for the current general user.
   * This observable will emit the latest general user data.
   * To refresh the general user data and get a new value, call the refreshGeneralUser() method.
   */
  public generalUser$: Observable<IGeneralUser> = this.generalUserSubject.asObservable();

  public constructor(
    private httpService: HttpClient,
    @Inject(AppEnvironment) private environment: IAppEnvironment,
  ) {
    Error.stackTraceLimit = Infinity;
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolClientId: environment.idpUserPoolWebClientId,
          userPoolId: environment.idpUserPoolId,
          mfa: {
            status: 'off',
            totpEnabled: false,
            smsEnabled: false,
          },
        },
      },
    });
    this.resetAuthenticationData();
    this.resurrectAuthenticationData();
    this.resurrectGeneralUserData();
  }

  // --------------------------------------------------------------------------------------------------------------
  // UTIL
  // --------------------------------------------------------------------------------------------------------------

  /**
   * used to clear the local browser storage
   */
  public resetSessionData(): void {
    localStorage.clear();
  }

  // TODO: why are we needing those handlers?
  public setSuccessfulAuthenticationHandler(handler: AuthHookHandler) {
    this.successfulAuthenticationHandler = handler;
  }

  public setUnauthorizedHandler(handler: HookHandler) {
    this.unauthorizedHandler = handler;
  }

  public setSessionExpiredHandler(handler: HookHandler) {
    this.sessionExpiredHandler = handler;
  }

  public setNotFoundHandler(handler: HookHandler) {
    this.notFoundHandler = handler;
  }

  public setUpdateConflictHandler(handler: HookHandler) {
    this.updateConflictHandler = handler;
  }

  public setServerErrorHandler(handler: HookHandler) {
    this.serverErrorHandler = handler;
  }

  public setServiceNotAvailableHandler(handler: HookHandler) {
    this.serviceNotAvailableHandler = handler;
  }

  public setNoConnectionHandler(handler: HookHandler) {
    this.noConnectionHandler = handler;
  }

  public setBidProhibitedError(handler: HookHandler) {
    this.bidProhibitedErrorHandler = handler;
  }

  /**
   *
   */
  public request(
    method: string,
    url: string,
    body?: any,
    headersAndOptions?: Record<string, string | boolean>,
    errorHandlers?: ErrorHandlers,
  ): Observable<any> {
    return this.makeRequest(method, `${this.backendApiBaseUrl}${url}`, body, headersAndOptions, errorHandlers, new Error('').stack);
  }

  /**
   *
   */
  public requestWithPrivileges(
    method: 'post' | 'get' | 'put' | 'delete' | 'patch',
    url: string,
    body?: any,
    headersAndOptions?: Record<string, string | boolean>,
    errorHandlers?: ErrorHandlers,
  ): Observable<any> {
    return this.makePrivilegedRequest(method, `${this.backendApiBaseUrl}${url}`, body, headersAndOptions, errorHandlers, new Error('').stack);
  }

  public requestV2WithPrivileges(
    method: 'post' | 'get' | 'put' | 'delete' | 'patch',
    url: string,
    body?: any,
    headersAndOptions?: Record<string, string | boolean>,
    errorHandlers?: ErrorHandlers,
  ): Observable<any> {
    return this.makePrivilegedRequest(method, `${this.backendV2ApiBaseUrl}${url}`, body, headersAndOptions, errorHandlers, new Error('').stack);
  }

  public requestFromReportParsingWithPrivileges(
    method: 'post' | 'get' | 'put' | 'delete' | 'patch',
    url: string,
    body?: any,
    headersAndOptions?: Record<string, string | boolean>,
    errorHandlers?: ErrorHandlers,
  ): Observable<any> {
    return this.makePrivilegedRequest(method, `${this.vehicleReportParsingServiceUrl}${url}`, body, headersAndOptions, errorHandlers, new Error('').stack);
  }

  /**
   *
   */
  private makeRequest(
    method: string,
    url: string,
    body?: any,
    headersAndOptions?: Record<string, string | boolean>,
    errorHandlers?: ErrorHandlers,
    stack?: string,
  ): Observable<any> {
    method = method.toLowerCase();
    const { reportProgress, observe, ...headers } = headersAndOptions ?? {};
    const httpOptions: any = {
      headers: {
        ...headers,
      },
      ...(reportProgress ? { reportProgress } : undefined),
      ...(observe ? { observe } : undefined),
    };

    if (typeof this.httpService[method] === 'undefined') {
      alert(`Invalid HTTP method "${method}" given for backend request.`);
      return Observable.create(observer => observer.error());
    }

    if (reportProgress && method !== 'get' && method !== 'delete') {
      return this.httpService[method](url, body, httpOptions).pipe(
        catchError((errorResponse: any) => {
          this.callErrorHandlers(errorResponse.status, errorHandlers);
          return throwError(() => errorResponse);
        }),
      );
    }

    return Observable.create(observer => {
      this.httpService[method](
        url,
        method === 'get' || method === 'delete' ? httpOptions : body,
        method === 'get' || method === 'delete' ? {} : httpOptions,
      ).subscribe(
        response => {
          observer.next(response);
          observer.complete();
        },
        (errorResponse: any) => {
          this.callErrorHandlers(errorResponse.status, errorHandlers);
          errorResponse.stack = stack;
          observer.error(errorResponse);
        },
      );
    });
  }

  private callErrorHandlers(status: number | null | undefined, errorHandlers?: ErrorHandlers): void {
    if (typeof errorHandlers?.[status] === 'function') {
      errorHandlers[status]();
      return;
    }

    switch (status ?? 0) {
      case 401:
        this.unauthorizedHandler();
        break;
      case 402:
        this.bidProhibitedErrorHandler();
        break;
      case 404:
        this.notFoundHandler();
        break;
      case 409:
        this.updateConflictHandler();
        break;
      case 419:
        this.sessionExpiredHandler();
        break;
      case 500:
        this.serverErrorHandler();
        break;
      case 0:
      case 503:
        this.serviceNotAvailableHandler();
        break;
      default:
        break;
    }
  }

  /**
   *
   */
  private makePrivilegedRequest(
    method: 'post' | 'get' | 'put' | 'delete' | 'patch',
    url: string,
    body?: any,
    headersAndOptions?: Record<string, string | boolean>,
    errorHandlers?: ErrorHandlers,
    stack?: string,
  ): Observable<any> {
    this.resurrectAuthenticationData();

    if (!this.lastAuthenticationResult?.userId) {
      return throwError(() => undefined);
    }

    return this.getFreshAccessTokens().pipe(
      switchMap((tokens: IUserTokens) => {
        const headersWithAuthentication = {
          ...headersAndOptions,
          UserId: `${this.lastAuthenticationResult.userId}`,
          Authorization: `Bearer ${tokens.accessToken}`,
          Identity: `Bearer ${tokens.idToken}`,
        };

        return this.makeRequest(method, url, body, headersWithAuthentication, errorHandlers, stack);
      }),
    );
  }

  /**
   * returns an uri encoded stringified object
   */
  public encodeParamObject(param: any): string {
    return encodeURIComponent(JSON.stringify(param));
  }

  /**
   * FIXME: could be private?
   */
  public generateIdempotencyHeader(): any {
    return {
      'idempotency-key': Math.random().toString().slice(2),
    };
  }

  // --------------------------------------------------------------------------------------------------------------
  // AUTH
  // TODO: could this section not be moved to the authentication - service?
  // --------------------------------------------------------------------------------------------------------------

  private isBuyerSellerUserSubject = new BehaviorSubject<IUserProfile[]>(null);
  public isBuyerSellerUser$ = this.isBuyerSellerUserSubject.pipe(
    map((userProfiles: IUserProfile[]) => {
      const sellerProfile = (userProfiles || []).some(({ type }) => type === EUserType.DEALERSHIP || type === EUserType.DEALERSHIP_SUPERVISOR);
      const buyerProfile = (userProfiles || []).some(({ type }) => type === EUserType.SALESMAN);

      return sellerProfile && buyerProfile;
    }),
    shareReplay(1),
  );

  public changeUserType(type: EUserType): Observable<IAuthenticationResult> {
    return of(<IAuthenticationResult>JSON.parse(localStorage.getItem('persistedAuthentication'))).pipe(
      catchError(() => of(null)),
      filter(Boolean),
      map(authResult => ({
        authResult,
        userProfile: this.userProfiles.find(userProfile => userProfile.type === type),
      })),
      filter(({ userProfile }) => Boolean(userProfile)),
      map(({ authResult, userProfile }) => ({
        ...authResult,
        type,
        userRole: userProfile.userRole,
      })),
      tap(() => (this.selectedUserType = type)),
      tap(authResult => localStorage.setItem('persistedAuthentication', JSON.stringify(authResult))),
    );
  }

  private getUserTypeFromStorage(): EUserType {
    try {
      return (<IAuthenticationResult>JSON.parse(localStorage.getItem('persistedAuthentication')))?.type;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Authenticates with the cognito
   */
  public authenticate(userId: string, password: string): Observable<IAuthenticationResult> {
    userId = userId.toLowerCase().trim();
    this.lastAuthenticationResult = this.notAuthenticated;
    // use defer to re-create the promise on retry
    return defer(() =>
      signIn({
        username: userId,
        password: password,
        options: { authFlowType: 'USER_PASSWORD_AUTH' },
      }),
    ).pipe(
      retry({
        count: 1, // security measure, just in case signOff() fails to sign off
        delay: error => {
          if (error.name === USER_ALREADY_AUTHENTICATED_EXCEPTION) {
            return this.signOff();
          }
          return throwError(() => error);
        },
      }),
      switchMap(({ nextStep }) => {
        if (nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
          // Force Password Change
          return of({
            ...this.notAuthenticated,
            authenticationChallenge: EAuthenticationChallenge.NEW_PASSWORD_REQUIRED,
          });
        }
        if (nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') {
          // Two-Factor Authentication - TOTP Code Required
          return of({
            ...this.notAuthenticated,
            authenticationChallenge: EAuthenticationChallenge.CONFIRM_SIGN_IN_WITH_TOTP_CODE,
          });
        }
        if (nextStep?.signInStep === 'DONE') {
          // Successful SignIn
          return this.refreshProfiles().pipe(
            switchMap(() => {
              if (this.availableUserTypes) {
                const userTypeFromStorage = this.getUserTypeFromStorage();
                this.selectedUserType = Validation.isUndefinedNullOrNaN(userTypeFromStorage) ? this.availableUserTypes[0] : userTypeFromStorage;
                return this.setUserProfile(this.selectedUserType);
              }
              return of(this.lastAuthenticationResult);
            }),
          );
        }
        // Untreated next step
        return of({
          ...this.notAuthenticated,
          authenticationError: new Error('Unknown next step, please contact support.'),
        });
      }),
      catchError(error =>
        of({
          ...this.notAuthenticated,
          authenticationError: error,
        }),
      ),
    );
  }

  /**
   * Request from cognito to reset the current user password
   * and sends a mail to the user afterwards
   */
  public requestPasswordReset(userMailId: string): Observable<void> {
    return from(resetPassword({ username: userMailId.toLowerCase() })).pipe(map(() => null));
  }

  /**
   * After a user did reset his password he will be notified by mail to
   * set a new password.
   *
   * This function communicates with cognito to confirm the new user password
   */
  public confirmPasswordReset(userMailId: string, code: string, newPassword: string): Observable<void> {
    return from(
      confirmResetPassword({
        username: userMailId.toLowerCase(),
        newPassword,
        confirmationCode: code,
      }),
    );
  }

  /**
   * gets the latest AuthenticationResult from local storage,
   *
   * if there is none returns this.notAuthenticated
   */
  public getLastAuthenticationResult(): IAuthenticationResult {
    let retrievedObject = null;

    try {
      retrievedObject = localStorage.getItem('persistedAuthentication');
    } catch (e) {
      return this.notAuthenticated;
    }

    if (!retrievedObject) {
      return this.notAuthenticated;
    }

    return JSON.parse(retrievedObject) as IAuthenticationResult;
  }

  /**
   * changes the user password in cognito
   */
  public changePassword(currentPassword: string, plainTextPassword: string): Observable<void> {
    return from(
      updatePassword({
        oldPassword: currentPassword,
        newPassword: plainTextPassword,
      }),
    );
  }

  /**
   * This function is used:
   * If the user is requested to enter a new password for SignIn
   * If the user is requested to enter a TOTP code for SignIn
   */
  public completeChallengeToSignIn(challengeResponse: string): Observable<IAuthenticationResult> {
    return from(confirmSignIn({ challengeResponse: challengeResponse })).pipe(
      switchMap(confirmation => {
        if (!confirmation) {
          return of(this.notAuthenticated);
        }
        // For Users with 2FA activated, after a password change, the user will be requested to enter a TOTP code
        if (confirmation.isSignedIn === true) {
          return this.refreshProfiles().pipe(
            switchMap(() => {
              if (this.availableUserTypes) {
                const userTypeFromStorage = this.getUserTypeFromStorage();
                this.selectedUserType = Validation.isUndefinedNullOrNaN(userTypeFromStorage) ? this.availableUserTypes[0] : userTypeFromStorage;
                return this.setUserProfile(this.selectedUserType);
              }
              return of(this.lastAuthenticationResult);
            }),
          );
        }
        if (confirmation.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') {
          // Two-Factor Authentication - TOTP Code Required
          return of({
            ...this.notAuthenticated,
            authenticationChallenge: EAuthenticationChallenge.CONFIRM_SIGN_IN_WITH_TOTP_CODE,
          });
        }
        // Untreated next step
        return of({
          ...this.notAuthenticated,
          authenticationError: new Error('Unknown next step, please contact support.'),
        });
      }),
    );
  }

  /**
   * Validates the current user token
   */
  public validateAuthentication(): Observable<IAuthenticationResult | null> {
    if (this.lastAuthenticationResult === null) {
      return of(null);
    }
    // TODO: Update persisted auth result her?
    return this.checkAuthenticationToken().pipe(
      catchError(() => {
        // TODO: No connection?
        this.resetAuthenticationData();
        this.removePersistedAuthentication();
        return of(this.lastAuthenticationResult); // which is: notAuthenticated
      }),
    );
  }

  /**
   * TODO: appropriate name for this function?
   */
  private refreshProfiles(): Observable<void> {
    const errorHandler = this.notFoundHandler;
    // we get 404 if no profiles
    this.notFoundHandler = NoHandler;
    return this.getUserInfo().pipe(
      switchMap(userInfo => (userInfo.migrationCompleted ? this.forceRefreshToken() : of(null)).pipe(map(() => userInfo))),
      map(userInfo => {
        this.userProfiles = userInfo.profiles;
        this.isBuyerSellerUserSubject.next(userInfo.profiles);
        this.availableUserTypes = this.userProfiles.map(p => p.type);
      }),
      switchMap(() => this.refreshCurrentGeneralUser().pipe(map(() => null))),
      catchError(e => {
        this.userProfiles = null;
        this.isBuyerSellerUserSubject.next(null);
        this.availableUserTypes = null;
        this.clearGeneralUser();
        if (e.status !== 404) {
          return throwError(e);
        }
        this.lastAuthenticationResult = this.authenticatedButNotConfirmed;
        return of();
      }),
      finalize(() => (this.notFoundHandler = errorHandler)),
    );
  }

  /**
   * forces cognito to refresh the user token
   */
  private forceRefreshToken(): Observable<void> {
    return from(getCurrentUser()).pipe(
      switchMap(() => from(fetchAuthSession({ forceRefresh: true }))),
      map(() => undefined),
    );
  }

  /**
   * updates the state to the latest authentication result &
   * persists the data
   */
  public updateAuthenticationData(updatedAuthenticationResult: IAuthenticationResult): void {
    this.lastAuthenticationResult = updatedAuthenticationResult;
    this.persistAuthentication();
  }

  /**
   * signs the current user out from cognito and removes
   * the authentication token from localstorage
   */
  public signOff(): Observable<void> {
    this.lastAuthenticationResult = this.notAuthenticated;

    this.removePersistedAuthentication();

    return from(signOut());
  }

  /**
   * persists the user profile to local storage
   */
  private setUserProfile(userType: EUserType): Observable<IAuthenticationResult> {
    const selectedProfile = this.userProfiles.filter(profile => profile.type === userType)[0];
    return this.getFreshAccessTokens().pipe(
      map(userTokens => {
        this.lastAuthenticationResult = {
          authenticated: true,
          userId: selectedProfile.mailAddress.trim(),
          internalUserId: selectedProfile.internalUserId,
          internalUserUUID: selectedProfile.internalUserUuid,
          privileges: selectedProfile.privileges,
          type: selectedProfile.type,
          token: userTokens.accessToken,
          userRole: selectedProfile.userRole,
        };
        this.persistAuthentication();
        this.successfulAuthenticationHandler(this.lastAuthenticationResult);
        return this.lastAuthenticationResult;
      }),
    );
  }

  /**
   * checks if the user has already an authentication token
   */
  private checkAuthenticationToken(): Observable<IAuthenticationResult> {
    return this.selectDefaultUserProfile();
  }

  /**
   * resets the current authentication result
   */
  public resetAuthenticationData(): void {
    this.lastAuthenticationResult = this.notAuthenticated;
  }

  /**
   * refreshes the current user token
   */
  public getFreshAccessTokens(): Observable<IUserTokens> {
    let networkError = false;
    const callback = event => {
      if (event.payload?.data?.error?.message === 'Network error') {
        networkError = true;
      }
    };
    const hubListener = Hub.listen('auth', callback);
    // automatically renews token as needed
    return from(fetchAuthSession()).pipe(
      map(authSession => ({
        accessToken: authSession.tokens.accessToken.toString(),
        idToken: authSession.tokens.idToken.toString(),
      })),
      catchError(e => {
        if (e.underlyingError?.message === 'Network error') {
          networkError = true;
        }
        if (!networkError) {
          return this.signOff().pipe(switchMap(() => throwError(() => e)));
        }
        return throwError(() => e);
      }),
      finalize(() => hubListener()),
    );
  }

  /**
   * persist latest authentication result to the local storage
   * the KEY to get that data from local storage is: `persistedAuthentication`
   */
  private persistAuthentication(): void {
    localStorage.setItem('persistedAuthentication', JSON.stringify(this.lastAuthenticationResult));
  }

  /**
   * gets the user info
   */
  private getUserInfo(): Observable<IUserInfo> {
    return this.getFreshAccessTokens().pipe(
      switchMap(tokens => {
        const authHeaders: Record<string, string> = {
          Authorization: `Bearer ${tokens.accessToken}`,
          Identity: `Bearer ${tokens.idToken}`,
        };
        return this.makeRequest('get', `${this.backendApiBaseUrl}/authenticated-user`, null, authHeaders, null, new Error('').stack);
      }),
    );
  }

  /**
   * removes authentication result from local storage
   */
  private removePersistedAuthentication(): void {
    localStorage.removeItem('persistedAuthentication');
    this.clearGeneralUser();
  }

  /**
   * TODO: comment
   */
  private resurrectAuthenticationData(): void {
    let retrievedObject: any = null;

    try {
      retrievedObject = localStorage.getItem('persistedAuthentication');
    } catch (e) {
      this.resetAuthenticationData();
      return;
    }

    const auth: IAuthenticationResult = JSON.parse(retrievedObject);

    if (auth !== null) {
      this.lastAuthenticationResult = auth;

      if (!this.lastAuthenticationResult.userId) {
        this.resetAuthenticationData();
        return;
      }

      this.lastAuthenticationResult.userId = this.lastAuthenticationResult.userId.trim();
    } else {
      this.resetAuthenticationData();
    }
  }

  private resurrectGeneralUserData(): void {
    let generalUser: IGeneralUser = null;

    try {
      generalUser = JSON.parse(localStorage.getItem('generalUser'));
    } catch (error) {
      console.error('Error while parsing general user from storage', error);
      generalUser = null;
    }

    if (!this.lastAuthenticationResult?.authenticated || (this.lastAuthenticationResult?.internalUserUUID ?? null) !== (generalUser?.uuid ?? null)) {
      this.clearGeneralUser();
      return;
    }

    this.generalUserSubject.next(generalUser);
  }

  /**
   * TODO: comment
   */
  private selectDefaultUserProfile(): Observable<IAuthenticationResult> {
    return this.refreshProfiles().pipe(
      switchMap(() => {
        if (this.availableUserTypes) {
          const userTypeFromStorage = this.getUserTypeFromStorage();
          this.selectedUserType = Validation.isUndefinedNullOrNaN(userTypeFromStorage) ? this.availableUserTypes[0] : userTypeFromStorage;

          return this.setUserProfile(this.selectedUserType);
        }
        return of(this.lastAuthenticationResult);
      }),
    );
  }

  /**
   * Get the current general user.
   */
  public getCurrentGeneralUserSnapshot(): Readonly<IGeneralUser> {
    return this.generalUserSubject.getValue();
  }

  /**
   * Get and refresh the current general user.
   * This will fetch the general user from the BE, persist the response in local storage and emit a new value to the
   * generalUser$ observable.
   */
  public refreshCurrentGeneralUser(): Observable<IGeneralUser> {
    return this.getFreshAccessTokens().pipe(
      switchMap(tokens => {
        const authHeaders: Record<string, string> = {
          Authorization: `Bearer ${tokens.accessToken}`,
          Identity: `Bearer ${tokens.idToken}`,
        };
        return this.makeRequest('get', `${this.backendApiBaseUrl}/users/profile/`, null, authHeaders);
      }),
      tap((generalUser: IGeneralUser) => this.setGeneralUser(generalUser)),
    );
  }

  private setGeneralUser(generalUser: IGeneralUser) {
    localStorage.setItem('generalUser', JSON.stringify(generalUser));
    this.generalUserSubject.next(generalUser);
  }

  private clearGeneralUser() {
    localStorage.removeItem('generalUser');
    this.generalUserSubject.next(null);
  }

  /**
   * TODO: Cache here?
   */
  public retrieveBusinessData(): Observable<IBusinessDataConfig> {
    return this.makePrivilegedRequest('get', `${this.backendApiBaseUrl}/public/config/business-data/`);
  }

  /**
   * TODO: Retrieve detail statistics for salesman
   */
  public getAuctionsConfig(): Observable<IAuctionsConfig> {
    // TODO: Cache?

    return this.makePrivilegedRequest('get', `${this.backendApiBaseUrl}/public/config/auctions`);
  }

  /**
   * TODO: merge getAuctionConfig && getAdminAuctionConfig?
   */
  public getAdminAuctionsConfig(): Observable<IAuctionsConfig> {
    return this.makePrivilegedRequest('get', `${this.backendApiBaseUrl}/admin/config/auctions`);
  }

  public updateAdminAuctionsConfig(updatedConfig: IAuctionsConfig): Observable<IAuctionsConfig> {
    return this.makePrivilegedRequest('post', `${this.backendApiBaseUrl}/admin/config/auctions`, updatedConfig);
  }

  public getAdminUsersConfig(): Observable<IUsersConfig> {
    return this.makePrivilegedRequest('get', `${this.backendApiBaseUrl}/admin/config/users`);
  }

  public getAdminBusinessConfig(): Observable<IBusinessDataConfig> {
    return this.makePrivilegedRequest('get', `${this.backendApiBaseUrl}/admin/config/business`);
  }

  public updateAdminBusinessConfig(updatedConfig: IBusinessDataConfig): Observable<IBusinessDataConfig> {
    return this.makePrivilegedRequest('post', `${this.backendApiBaseUrl}/admin/config/business`, updatedConfig);
  }

  public updateAdminUsersConfig(updatedConfig: IUsersConfig): Observable<IUsersConfig> {
    return this.makePrivilegedRequest('post', `${this.backendApiBaseUrl}/admin/config/users`, updatedConfig);
  }

  public getServiceMetaInfo(): Observable<IServiceMetaData> {
    return this.makeRequest('get', `${this.backendApiBaseUrl}/meta`);
  }

  public getBusinessState(): Observable<IBusinessState> {
    return this.makeRequest('get', `${this.backendApiBaseUrl}/meta/business-state`);
  }

  public getMakeOptions(): Observable<IVehicleMake[]> {
    return this.makeRequest('get', `${this.backendApiBaseUrl}/public/trade-in/make/_all`);
  }

  public getBaseModelOptionsFor(make: IVehicleMake, type = EVehicleType.PKW): Observable<IVehicleBaseModel[]> {
    return this.makeRequest('get', `${this.backendApiBaseUrl}/public/trade-in/make/${make.internalReference}/model/_all?type=${type}`);
  }

  public getSubModelOptionsFor(make: IVehicleMake, model: IVehicleBaseModel): Observable<IVehicleBaseModel[]> {
    const type = model.type || EVehicleType.PKW;
    return this.makeRequest(
      'get',
      `${this.backendApiBaseUrl}/public/trade-in/make/${make.internalReference}/model/${model.internalReference}/sub-model/_all?type=${type}`,
    );
  }

  public getBodyOptionsFor(
    make: IVehicleMake,
    model: IVehicleBaseModel,
    subModel: IVehicleSubModel,
    type = EVehicleType.PKW,
  ): Observable<IVehicleGearingOption[]> {
    return this.makeRequest(
      'get',
      `${this.backendApiBaseUrl}/public/trade-in/make/${make.internalReference}/model/` +
        `${model.internalReference}/sub-model/${subModel.internalReference}/body/_all?type=${type}`,
    );
  }

  public getEngineOptionsFor(
    make: IVehicleMake,
    model: IVehicleBaseModel,
    subModel: IVehicleSubModel,
    type = EVehicleType.PKW,
  ): Observable<IVehicleEngineOption[]> {
    return this.makeRequest(
      'get',
      `${this.backendApiBaseUrl}/public/trade-in/make/${make.internalReference}/model/` +
        `${model.internalReference}/sub-model/${subModel.internalReference}/engine/_all?type=${type}`,
    );
  }

  public getGearingOptionsFor(
    make: IVehicleMake,
    model: IVehicleBaseModel,
    subModel: IVehicleSubModel,
    type = EVehicleType.PKW,
  ): Observable<IVehicleGearingOption[]> {
    return this.makeRequest(
      'get',
      `${this.backendApiBaseUrl}/public/trade-in/make/${make.internalReference}/model/` +
        `${model.internalReference}/sub-model/${subModel.internalReference}/gearing/_all?type=${type}`,
    );
  }

  // TODO: Only use this eventually:
  public areMandatoryVehicleImagesSet(vehicleImages: IVehicleImage[]): boolean {
    return vehicleImages.length >= 4;
  }

  /* start: auction - creation - process TODO: this definitely not belongs into here (sellerService) */
  public areAuctionsInCreation(): boolean {
    return this.getAuctionInCreation() !== null || this.getDraftedAuctionInCreation() !== null;
  }

  public removeAuctionInCreation(): void {
    localStorage.removeItem('auction-creation-in-progress');
  }

  public removeDraftedAuctionInCreation(): void {
    localStorage.removeItem('drafted-auction-creation-in-progress');
  }

  public getAuctionInCreation(): IAuction {
    const auctionInCreation: IAuction = JSON.parse(localStorage.getItem('auction-creation-in-progress'));

    if (!Validation.isUndefinedOrNull(auctionInCreation)) {
      return auctionInCreation;
    } else {
      return null;
    }
  }

  public getDraftedAuctionInCreation(): IAuction {
    const draftedAuctionInCreation: IAuction = JSON.parse(localStorage.getItem('drafted-auction-creation-in-progress'));

    if (!Validation.isUndefinedOrNull(draftedAuctionInCreation)) {
      return draftedAuctionInCreation;
    } else {
      return null;
    }
  }

  /* end: auction - creation - process */

  public getLanguage(): Observable<string> {
    const userId = this.getLastAuthenticationResult().internalUserId;
    const userType = this.getLastAuthenticationResult().type;

    if (!userId) {
      return throwError({ status: 401 });
    }

    const path = this.getPathByUserType(userType);

    return iif(
      () => !!path,
      this.makePrivilegedRequest('get', `${this.backendApiBaseUrl}/profile/${path}/${userId}/lang`) as Observable<ICustomerLanguage>,
      of({ preferredLanguage: null } as ICustomerLanguage),
    ).pipe(map(res => res.preferredLanguage));
  }

  public setLanguage(newLang: string): Observable<void> {
    const userId = this.getLastAuthenticationResult().internalUserId;
    const userType = this.getLastAuthenticationResult().type;

    if (!userId) {
      return throwError({ status: 401 });
    }

    const path = this.getPathByUserType(userType);

    if (path) {
      return this.makePrivilegedRequest('put', `${this.backendApiBaseUrl}/profile/${path}/${userId}/lang`, {
        preferredLanguage: newLang,
      } as ICustomerLanguage);
    }

    return of();
  }

  private getPathByUserType(userType: EUserType): string {
    return {
      [EUserType.DEALERSHIP]: 'dealership',
      [EUserType.DEALERSHIP_SUPERVISOR]: 'dealership',
      [EUserType.SALESMAN]: 'salesman',
      [EUserType.TRANSPORTATION_PROVIDER]: 'transportation-provider',
    }[userType];
  }

  // --------------------------------------------------------------------------------------------------------------
  // SYSTEM
  // TODO: move this section to public - api - service?
  // --------------------------------------------------------------------------------------------------------------
  public getConfigSystem(): Observable<Partial<Record<EPublicEnvironmentConfigKeys, string>>> {
    return this.makeRequest('get', `${this.backendApiBaseUrl}/public/config/system`);
  }

  /**
   * 2FA - Two-Factor Authentication methods
   */

  /**
   * If multiple MFA methods are enabled for the user,
   * the signIn API will return CONTINUE_SIGN_IN_WITH_MFA_SELECTION
   * as the next step in the auth flow.
   */
  public fetchUserMfaPreference(): Observable<{ totpEnabled: boolean }> {
    return from(fetchMFAPreference()).pipe(map(output => (output.enabled?.includes('TOTP') ? { totpEnabled: true } : { totpEnabled: false })));
  }

  // Used to enable and disable the User's MFA Preference
  private updateUserMfaPreference(totpOption: 'PREFERRED' | 'DISABLED'): Observable<void> {
    return from(
      updateMFAPreference({
        sms: 'DISABLED',
        totp: totpOption,
      }),
    ).pipe(
      catchError(error => {
        console.log(error);
        return throwError(() => 'Error updating MFA preference! Please contact support.');
      }),
    );
  }

  /**
   * Used to enable TOTP after a user is signed in!
   * TOTP can be set up by calling the setUpTOTP and verifyTOTPSetup
   * Invoke the setUpTOTP API to generate a TOTPSetupDetails object which
   * should be used to configure an Authenticator app (Microsoft/Google Authenticator)
   */
  public handleMfaSetup(): Observable<string> {
    // The sharedSecret should be used to create a QRCode for the user to scan
    return from(setUpTOTP()).pipe(map(totpSetupDetails => totpSetupDetails.sharedSecret));
  }

  public handleMfaVerification(totpCode: string): Observable<void> {
    return from(
      verifyTOTPSetup({
        code: totpCode,
        options: { friendlyDeviceName: 'COS CRM' },
      }),
    ).pipe(switchMap(() => this.updateUserMfaPreference('PREFERRED')));
  }

  public disableUserMfa(): Observable<void> {
    return from(this.updateUserMfaPreference('DISABLED'));
  }
}
