import { booleanAttribute, ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Color, EnzoIcon, Spacer } from '@caronsale/enzo';

@Component({
  selector: 'enzo-text',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  standalone: true,
  host: {
    '[kind]': 'kind()',
    '[align]': 'align()',
    '[color]': 'color()',
    '[overflow]': 'overflow()',
    '[icon]': 'icon()',
    '[iconSize]': 'iconSize()',
    '[iconColor]': 'iconColor()',
    '[iconPosition]': 'iconPosition()',
    '[iconSpacing]': 'iconSpacing()',
    '[balance]': 'balance()',
  },
})
export class EnzoTextComponent {
  public kind = input<'body' | 'body-medium' | 'body-bold' | 'body-italic' | 'label' | 'emphasis-label' | 'caption' | 'overline'>('body');
  public align = input<'left' | 'center' | 'right'>('left');
  public color = input<Color | 'inherit'>('inherit');
  public overflow = input<'ellipsis'>();
  public icon = input<EnzoIcon['icon']>();
  public iconSize = input<EnzoIcon['size']>('medium');
  public iconColor = input<EnzoIcon['color'] | 'currentColor'>('currentColor');
  public iconPosition = input<'leading' | 'trailing'>('leading');
  public iconSpacing = input<Spacer>('3');
  public balance = input(false, { transform: booleanAttribute });
}
