import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@cosCoreEnvironments/environment';
import * as Sentry from '@sentry/angular';
import { SentryErrorHandler } from '@sentry/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { CosCoreClient } from '@caronsale/frontend-services';
import { ProductAnalyticsService } from '@cos/services/product-analytics/product-analytics.service';
import { EUserRole } from '@caronsale/cos-models';

const HTTP_STATUS_TEXT = {
  '0': 'UnknownError',
  '200': 'OK',
  '201': 'Created',
  '202': 'Accepted',
  '203': 'Non-AuthoritativeInformation',
  '204': 'NoContent',
  '205': 'ResetContent',
  '206': 'PartialContent',
  '300': 'MultipleChoices',
  '301': 'MovedPermanently',
  '302': 'Found',
  '303': 'SeeOther',
  '304': 'NotModified',
  '305': 'UseProxy',
  '306': 'Unused',
  '307': 'TemporaryRedirect',
  '400': 'BadRequest',
  '401': 'Unauthorized',
  '402': 'PaymentRequired',
  '403': 'Forbidden',
  '404': 'NotFound',
  '405': 'MethodNotAllowed',
  '406': 'NotAcceptable',
  '407': 'ProxyAuthenticationRequired',
  '408': 'RequestTimeout',
  '409': 'Conflict',
  '410': 'Gone',
  '411': 'LengthRequired',
  '412': 'PreconditionRequired',
  '413': 'RequestEntryTooLarge',
  '414': 'Request-URIToLong',
  '415': 'UnsupportedMediaType',
  '416': 'RequestedRangeNotSatisfiable',
  '417': 'ExpectationFailed',
  '418': "I'maTeapot",
  '429': 'TooManyRequests',
  '500': 'InternalServerError',
  '501': 'NotImplemented',
  '502': 'BadGateway',
  '503': 'ServiceUnavailable',
  '504': 'GatewayTimeout',
  '505': 'HTTPVersionNotSupported',
};

const COS_BACKEND_INDICATOR = 'caronsale.de/api';

@Injectable()
export class SentryGlobalErrorHandler extends SentryErrorHandler implements ErrorHandler {
  public constructor(
    private cosCoreClient: CosCoreClient,
    private productAnalyticsService?: ProductAnalyticsService,
  ) {
    super({
      showDialog: false,
      logErrors: !environment.disableLogging, // to the console, keeps sentry sending them to sentry
    });
  }

  public handleError(error: any): void {
    const generalUser = this.cosCoreClient.getCurrentGeneralUserSnapshot();
    const lastAuthenticationResult = this.cosCoreClient.getLastAuthenticationResult();
    Sentry.setUser({
      id: generalUser?.['id'], // 845, the "id" number to find the user in the CRM
      uuid: generalUser?.uuid, // the uuid for the backend
      crmUrl: this.getCrmUrl(lastAuthenticationResult.userRole, generalUser?.uuid),
    });

    const sessionReplayUrl = this.productAnalyticsService?.getSessionReplayUrl();
    if (sessionReplayUrl) {
      Sentry.setContext('sessionReplay', { url: sessionReplayUrl });
    }

    const chunkFailedMessage = /Loading chunk \S+ failed/;
    if (chunkFailedMessage.test(error?.message)) {
      if (window['SENTRY_CLIENT']) {
        window['SENTRY_CLIENT'].getOptions().enabled = false;
      }
      window.location.reload();
    }

    if (error instanceof HttpErrorResponse) {
      // for our backend api: show the path part after caronsale/api
      const url = error.url?.includes(COS_BACKEND_INDICATOR)
        ? error.url.substring(error.url.indexOf(COS_BACKEND_INDICATOR) + COS_BACKEND_INDICATOR.length)
        : error.url;
      const extractedError = new Error(`HTTP ${error.status} ${HTTP_STATUS_TEXT[error.status]} ${url}`);
      extractedError.name = 'HttpErrorResponse';
      // cos core client saves a stack to show from where it was called
      extractedError.stack = (error as any).stack ?? extractedError.stack;
      Sentry.setContext('responseData', error.error);
      super.handleError(extractedError);
    } else {
      Sentry.setContext('responseData', undefined);
      super.handleError(error);
    }
  }

  private getCrmUrl(userRole: EUserRole, userUuid: string): string | undefined {
    const roleString =
      userRole === EUserRole.SELLERS_SUPERVISORS ? 'supervisor' : userRole === EUserRole.SELLERS ? 'seller' : userRole === EUserRole.BUYERS ? 'buyer' : null;
    return roleString && userUuid ? `https://crm.caronsale.de/admin/user-management?${roleString}-uuid=${userUuid}` : undefined;
  }
}
