import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Color, Icon } from '@caronsale/enzo';

@Component({
  selector: 'enzo-icon',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  standalone: true,
  host: {
    '[color]': 'color()',
    '[icon]': 'icon()',
    '[size]': 'size()',
  },
})
export class EnzoIconComponent {
  // transform the empty string, null or undefined to "inherit"
  public color = input<Color | 'inherit', Color | 'inherit' | ''>('inherit', { transform: value => (!value ? 'inherit' : value) });
  public icon = input<Icon>();
  public size = input<'small' | 'medium'>('medium');
}
