import { ChangeDetectionStrategy, Component, ElementRef, inject, input, output } from '@angular/core';
import {
  Components,
  type EnzoActivePageChangePayload as IEnzoHeaderEnzoActivePageChangePayload,
  HeaderAccountBannerConfig,
  HeaderActivePage,
  HeaderLogoConfig,
  HeaderMenuOptions,
  Language,
  languages,
} from '@caronsale/enzo';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HTMLStencilElement } from '@caronsale/enzo/dist/types/stencil-public-runtime';

@Component({
  selector: 'enzo-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  standalone: true,
  host: {
    '[accountBanner]': 'accountBanner()',
    '[activePage]': 'activePage()',
    '[hidePageSwitcher]': 'hidePageSwitcher()',
    '[logoConfig]': 'logoConfig()',
    '[menuOptions]': 'menuOptions()',
    '[supportedLanguages]': 'supportedLanguages()',
  },
})
export class EnzoHeaderComponent {
  private el: Components.EnzoHeader & HTMLStencilElement = inject(ElementRef).nativeElement;

  public accountBanner = input<HeaderAccountBannerConfig>();
  public activePage = input<HeaderActivePage>();
  public hidePageSwitcher = input<boolean>(false);
  public logoConfig = input.required<HeaderLogoConfig>();
  public menuOptions = input<HeaderMenuOptions>([]);
  public supportedLanguages = input<Array<Language>>(languages);

  public enzoActivePageChange = output<CustomEvent<IEnzoHeaderEnzoActivePageChangePayload>>();

  public constructor() {
    fromEvent(this.el, 'enzoActivePageChange')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        event.stopImmediatePropagation();
        this.enzoActivePageChange.emit(event as CustomEvent<IEnzoHeaderEnzoActivePageChangePayload>);
      });
  }
}
