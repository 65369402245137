import { Directive } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessor } from './value-accessor';

@Directive({
  /* eslint-disable-next-line @angular-eslint/directive-selector */
  selector: 'enzo-checkbox, enzo-toggle',
  host: {
    '(enzoInput)': 'handleChangeEvent($event.target.checked)',
    '(focusout)': 'onTouched()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BooleanValueAccessorDirective,
      multi: true,
    },
  ],
})
export class BooleanValueAccessorDirective extends ValueAccessor {
  public writeValue(value: any) {
    this.lastValue = value == null ? false : value;
    this.el.nativeElement.checked = this.lastValue;
  }
}
