export function parseGermanLocaleNumberString(input: string): number {
  const minus = '-';
  const plus = '+';
  const numberSymbolDecimal = ',';
  const numberSymbolGroup = '.';
  const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  let value = 1;
  let divisor = 1;

  input = input?.trim().replaceAll(numberSymbolGroup, '');

  if (!input) {
    throw new Error();
  }

  if (input[0] === minus) {
    value = -1;
    divisor = -1;
    input = input.slice(1);
  }

  if (input[0] === plus) {
    input = input.slice(1);
  }

  if (input.length === 0) {
    throw new Error();
  }

  if (input[0] === numberSymbolDecimal && input.length > 1 && digits.includes(input[1])) {
    value = 0;
  } else {
    if (digits.includes(input[0])) {
      let sequence = '';

      while (digits.includes(input[0])) {
        sequence = sequence + input[0];
        input = input.slice(1);
      }

      value = value * parseInt(sequence);

      if (input.length === 0) {
        return value;
      }
    } else {
      throw new Error();
    }
  }

  if (input[0] === numberSymbolDecimal) {
    input = input.slice(1);

    while (input.length > 0 && digits.includes(input[0])) {
      divisor = divisor * 10;
      value = value + parseInt(input[0]) / divisor;
      input = input.slice(1);
    }
  }

  return value;
}
