import { Directive } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessor } from './value-accessor';

@Directive({
  /* eslint-disable-next-line @angular-eslint/directive-selector */
  selector: 'enzo-textfield:not([type=number])',
  host: {
    '(enzoInput)': 'handleChangeEvent($event.target.value)',
    '(focusout)': 'onTouched()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextValueAccessorDirective,
      multi: true,
    },
  ],
})
export class TextValueAccessorDirective extends ValueAccessor {
  public writeValue(value: any) {
    super.writeValue(value == null ? '' : value);
  }
}
