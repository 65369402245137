import { ChangeDetectionStrategy, Component, ElementRef, inject, input, output } from '@angular/core';
import { Components, EnzoLanguageChangePayload, Language } from '@caronsale/enzo';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HTMLStencilElement } from '@caronsale/enzo/dist/types/stencil-public-runtime';

@Component({
  selector: 'enzo-context',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  standalone: true,
  host: {
    '[language]': 'language()',
    '[logos]': 'logos()',
  },
})
export class EnzoContextComponent {
  private el: Components.EnzoContext & HTMLStencilElement = inject(ElementRef).nativeElement;

  public language = input<Language>('de');
  public logos = input<Record<string, string>>();

  public enzoLanguageChange = output<CustomEvent<EnzoLanguageChangePayload>>();

  public constructor() {
    fromEvent(this.el, 'enzoLanguageChange')
      .pipe(takeUntilDestroyed())
      .subscribe(event => {
        event.stopImmediatePropagation();
        this.enzoLanguageChange.emit(event as CustomEvent<EnzoLanguageChangePayload>);
      });
  }
}
